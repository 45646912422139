import { useEffect, useState } from "react";
import { doc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "@/lib/firebase";
import confetti from "canvas-confetti";
import { useToast } from "@/components/common/Use-toast";

interface AutoRaffleDrawProps {
  raffle: any;
  setSpinning: (spinning: boolean) => void;
  setPrizeNumber: (number: number) => void;
}

const AutoRaffleDraw = ({
  raffle,
  setSpinning,
  setPrizeNumber,
}: AutoRaffleDrawProps) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const { toast } = useToast();

  const triggerWinningAnimation = () => {
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;

    const randomInRange = (min: number, max: number) => {
      return Math.random() * (max - min) + min;
    };

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();
      if (timeLeft <= 0) return clearInterval(interval);

      confetti({
        particleCount: 3,
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        origin: { x: randomInRange(0.1, 0.9), y: randomInRange(0.1, 0.9) },
        colors: ["#FFD700", "#FFA500", "#FF4500"],
        zIndex: 9999,
      });
    }, 50);
  };

  useEffect(() => {
    const checkAndDrawWinner = async () => {
      if (isDrawing || !raffle || raffle.status !== "active") return;

      try {
        const now = new Date();
        let raffleDateTime;

        if (raffle.endDate instanceof Timestamp) {
          raffleDateTime = new Date(
            `${raffle.endDate.toDate().toISOString().split("T")[0]}T${
              raffle.raffleTime
            }`
          );
        } else {
          raffleDateTime = new Date(`${raffle.endDate}T${raffle.raffleTime}`);
        }

        // בדיקה אם הגענו לזמן ההגרלה (בטווח של 2 שניות)
        const timeDiff = Math.abs(now.getTime() - raffleDateTime.getTime());
        if (timeDiff <= 2000) {
          console.log("Starting raffle draw...");
          setIsDrawing(true);

          // טיפול במקרה שאין משתתפים
          if (!raffle.participants || raffle.participants.length === 0) {
            console.log("No participants, ending raffle");
            await updateDoc(doc(db, "raffles", raffle.id), {
              status: "completed",
              endDate: Timestamp.now(),
            });
            return;
          }

          // יצירת מערך משוקלל של משתתפים לפי מספר הכרטיסים
          const weightedParticipants: string[] = [];
          raffle.participants.forEach(
            (participant: { id: string; entries: number }) => {
              for (let i = 0; i < participant.entries; i++) {
                weightedParticipants.push(participant.id);
              }
            }
          );

          // בחירת זוכה
          const winnerIndex = Math.floor(
            Math.random() * weightedParticipants.length
          );
          const winnerId = weightedParticipants[winnerIndex];
          const winner = raffle.participants.find(
            (p: { id: string }) => p.id === winnerId
          );

          // בדיקה וטיפול בקוד הפרס
          const availableCode = raffle.prizeCodes.find(
            (code: { isRevealed: boolean }) => !code.isRevealed
          );

          if (!availableCode) {
            console.error("No available prize code found");
            return;
          }

          // הגדרת מספר הפרס לגלגל
          const displayIndex = raffle.participants.findIndex(
            (p: { id: string }) => p.id === winnerId
          );
          console.log("Setting prize number:", displayIndex);
          setPrizeNumber(displayIndex);
          setSpinning(true);

          // עדכון הזוכה והקוד לאחר הסיבוב
          setTimeout(async () => {
            console.log("Updating winner in database...");
            await updateDoc(doc(db, "raffles", raffle.id), {
              winner: {
                userId: winner.id,
                username: winner.username,
                code: availableCode.code,
              },
              [`prizeCodes.${availableCode.id}.isRevealed`]: true,
              [`prizeCodes.${availableCode.id}.claimedBy`]: winner.id,
              [`prizeCodes.${availableCode.id}.revealDate`]: Timestamp.now(),
              status: "completed",
              endDate: Timestamp.now(),
            });

            toast({
              title: "🎉 יש לנו זוכה! 🎉",
              description: `מזל טוב ל${winner.username}!`,
            });

            triggerWinningAnimation();
            setSpinning(false);
          }, 10000); // זמן הסיבוב של הגלגל
        }
      } catch (error) {
        console.error("Error in raffle draw:", error);
        setSpinning(false);
        toast({
          title: "שגיאה בהגרלה",
          description: "אירעה שגיאה בביצוע ההגרלה",
          variant: "destructive",
        });
      } finally {
        setIsDrawing(false);
      }
    };

    // בדיקה כל חצי שנייה במקום כל שנייה
    const timer = setInterval(checkAndDrawWinner, 500);
    return () => clearInterval(timer);
  }, [raffle, isDrawing, setPrizeNumber, setSpinning, toast]);

  return null;
};

export default AutoRaffleDraw;
