import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  orderBy,
  Timestamp,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { DialogHeader, DialogFooter } from "@/components/common/Dialog";
import { useToast } from "@/components/common/Use-toast";
import { Input } from "@/components/ui/input";
import { Dialog, DialogContent, DialogTitle } from "@radix-ui/react-dialog";
import { Label } from "@/components/common/Label";
import { db } from "@/lib/firebase";
import { Edit2, Trash2, Info, Loader2 } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface PrizeCode {
  id: string;
  code: string;
  isRevealed: boolean;
  revealDate?: Timestamp;
  claimedBy?: string;
}

interface Participant {
  id: string;
  username: string;
  entries: number;
  joinedAt: Timestamp;
  points: number;
}

interface Raffle {
  id: string;
  title: string;
  description: string;
  date: Timestamp;
  endDate: Timestamp;
  scheduleTime: string; // HH:mm format
  scheduleDay?: number; // 1-7 for weekly
  scheduleDate?: number; // 1-31 for monthly
  participants: Participant[];
  totalParticipants: number;
  status: "upcoming" | "active" | "completed";
  type: "oneTime" | "daily" | "weekly" | "monthly";
  createdAt: Timestamp;
  prizeCodes: PrizeCode[];
  imageUrl: string;
  pointsCost: number;
  maxTicketsPerUser?: number;
  minPointsRequired?: number;
  skipWeekend?: boolean;
}

interface NewRaffle {
  title: string;
  description: string;
  date: string;
  endDate: string;
  scheduleTime: string;
  scheduleDay?: number;
  scheduleDate?: number;
  type: "oneTime" | "daily" | "weekly" | "monthly";
  status: "upcoming" | "active" | "completed";
  prizeCodes: string[];
  imageUrl: string;
  pointsCost: number;
  maxTicketsPerUser?: number;
  minPointsRequired?: number;
  skipWeekend?: boolean;
}

interface FormErrors {
  title?: string;
  description?: string;
  date?: string;
  scheduleTime?: string;
  scheduleDay?: string;
  scheduleDate?: string;
  pointsCost?: string;
  maxTicketsPerUser?: string;
  minPointsRequired?: string;
  prizeCodes?: string[];
  imageUrl?: string;
}

const RAFFLE_TYPES = [
  { value: "oneTime", label: "חד פעמית" },
  { value: "daily", label: "יומית" },
  { value: "weekly", label: "שבועית" },
  { value: "monthly", label: "חודשית" },
] as const;

const DAYS_OF_WEEK = [
  { value: 1, label: "ראשון" },
  { value: 2, label: "שני" },
  { value: 3, label: "שלישי" },
  { value: 4, label: "רביעי" },
  { value: 5, label: "חמישי" },
  { value: 6, label: "שישי" },
  { value: 7, label: "שבת" },
];

const DEFAULT_NEW_RAFFLE: NewRaffle = {
  title: "",
  description: "",
  date: "",
  endDate: "",
  scheduleTime: "12:00",
  type: "oneTime",
  status: "upcoming",
  prizeCodes: [""],
  imageUrl: "",
  pointsCost: 0,
  maxTicketsPerUser: undefined,
  minPointsRequired: undefined,
  skipWeekend: false,
  scheduleDay: 1,
  scheduleDate: 1,
};

const formatDate = (timestamp: Timestamp | null) => {
  if (!timestamp) return "-";
  return new Date(timestamp.seconds * 1000).toLocaleDateString("he-IL", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const getStatusColor = (status: string) => {
  const colors = {
    upcoming: "text-yellow-600",
    active: "text-green-600",
    completed: "text-blue-600",
  };
  return colors[status as keyof typeof colors] || "";
};

const getStatusText = (status: string) => {
  const texts = {
    upcoming: "מתוכננת",
    active: "פעילה",
    completed: "הסתיימה",
  };
  return texts[status as keyof typeof texts] || status;
};

const AdminRaffles = () => {
  const [raffles, setRaffles] = useState<Raffle[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingRaffle, setEditingRaffle] = useState<Raffle | null>(null);
  const [newRaffle, setNewRaffle] = useState<NewRaffle>(DEFAULT_NEW_RAFFLE);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const q = query(collection(db, "raffles"), orderBy("date", "desc"));
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const rafflesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Raffle[];
        setRaffles(rafflesData);
      },
      (error) => {
        console.error("Error fetching raffles:", error);
        toast({
          title: "שגיאה בטעינת נתונים",
          description: "אירעה שגיאה בטעינת ההגרלות. אנא נסה שוב מאוחר יותר.",
          variant: "destructive",
        });
      }
    );
    return () => unsubscribe();
  }, [toast]);

  const handleDateChange = (
    value: string,
    type: "oneTime" | "daily" | "weekly" | "monthly"
  ) => {
    let startDate: Date;
    let endDate: Date;

    try {
      switch (type) {
        case "oneTime":
          startDate = new Date(value);
          endDate = new Date(value);
          break;

        case "daily":
          startDate = new Date(value);
          endDate = new Date(value);
          endDate.setMonth(endDate.getMonth() + 1);
          break;

        case "weekly": {
          const [year, week] = value.split("-W");
          startDate = new Date(Number(year), 0, 1 + (Number(week) - 1) * 7);
          endDate = new Date(startDate);
          endDate.setMonth(endDate.getMonth() + 1);
          break;
        }

        case "monthly":
          startDate = new Date(value + "-01");
          endDate = new Date(startDate);
          endDate.setMonth(endDate.getMonth() + 3);
          break;

        default:
          return;
      }

      if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
        setNewRaffle((prev) => ({
          ...prev,
          date: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
        }));
        if (formErrors.date) {
          setFormErrors((prev) => ({ ...prev, date: undefined }));
        }
      }
    } catch (error) {
      setFormErrors((prev) => ({
        ...prev,
        date: "תאריך לא תקין",
      }));
    }
  };

  const isValidUrl = (url: string): boolean => {
    if (!url) return true; // Allow empty URL
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    let isValid = true;

    // Title validation
    if (!newRaffle.title.trim()) {
      errors.title = "שדה חובה";
      isValid = false;
    } else if (newRaffle.title.length < 3) {
      errors.title = "הכותרת חייבת להכיל לפחות 3 תווים";
      isValid = false;
    }

    // Description validation
    if (!newRaffle.description.trim()) {
      errors.description = "שדה חובה";
      isValid = false;
    } else if (newRaffle.description.length < 10) {
      errors.description = "התיאור חייב להכיל לפחות 10 תווים";
      isValid = false;
    }

    // Date validation for one-time raffles
    if (newRaffle.type === "oneTime" && !newRaffle.date) {
      errors.date = "שדה חובה";
      isValid = false;
    }

    // Schedule validation
    if (!newRaffle.scheduleTime) {
      errors.scheduleTime = "נא לבחור שעה";
      isValid = false;
    }

    if (newRaffle.type === "weekly" && !newRaffle.scheduleDay) {
      errors.scheduleDay = "נא לבחור יום";
      isValid = false;
    }

    if (newRaffle.type === "monthly" && !newRaffle.scheduleDate) {
      errors.scheduleDate = "נא לבחור תאריך";
      isValid = false;
    }

    // Points cost validation
    if (newRaffle.pointsCost < 0) {
      errors.pointsCost = "המחיר חייב להיות חיובי";
      isValid = false;
    }

    // Max tickets validation
    if (
      newRaffle.maxTicketsPerUser !== undefined &&
      newRaffle.maxTicketsPerUser < 1
    ) {
      errors.maxTicketsPerUser = "מספר הכרטיסים חייב להיות חיובי";
      isValid = false;
    }

    // Min points validation
    if (
      newRaffle.minPointsRequired !== undefined &&
      newRaffle.minPointsRequired < 0
    ) {
      errors.minPointsRequired = "מספר הנקודות חייב להיות חיובי";
      isValid = false;
    }

    // Prize codes validation
    const prizeCodeErrors: string[] = [];
    newRaffle.prizeCodes.forEach((code, index) => {
      if (!code.trim()) {
        prizeCodeErrors[index] = "שדה חובה";
        isValid = false;
      } else if (code.length < 3) {
        prizeCodeErrors[index] = "הקוד חייב להכיל לפחות 3 תווים";
        isValid = false;
      }
    });
    if (prizeCodeErrors.length > 0) {
      errors.prizeCodes = prizeCodeErrors;
    }

    // Image URL validation
    if (!isValidUrl(newRaffle.imageUrl)) {
      errors.imageUrl = "כתובת URL לא תקינה";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const raffleDate = new Date(
        `${newRaffle.date}T${newRaffle.scheduleTime}`
      );
      const endDate = new Date(
        `${newRaffle.endDate}T${newRaffle.scheduleTime}`
      );

      if (editingRaffle) {
        const updates: Partial<Raffle> = {};

        if (editingRaffle.title !== newRaffle.title)
          updates.title = newRaffle.title;
        if (editingRaffle.description !== newRaffle.description)
          updates.description = newRaffle.description;
        if (formatDate(editingRaffle.date) !== raffleDate.toLocaleDateString())
          updates.date = Timestamp.fromDate(raffleDate);
        if (formatDate(editingRaffle.endDate) !== endDate.toLocaleDateString())
          updates.endDate = Timestamp.fromDate(endDate);
        if (editingRaffle.scheduleTime !== newRaffle.scheduleTime)
          updates.scheduleTime = newRaffle.scheduleTime;
        if (editingRaffle.scheduleDay !== newRaffle.scheduleDay)
          updates.scheduleDay = newRaffle.scheduleDay;
        if (editingRaffle.scheduleDate !== newRaffle.scheduleDate)
          updates.scheduleDate = newRaffle.scheduleDate;
        if (editingRaffle.type !== newRaffle.type)
          updates.type = newRaffle.type;
        if (editingRaffle.status !== newRaffle.status)
          updates.status = newRaffle.status;
        if (editingRaffle.imageUrl !== newRaffle.imageUrl)
          updates.imageUrl = newRaffle.imageUrl;
        if (editingRaffle.pointsCost !== newRaffle.pointsCost)
          updates.pointsCost = newRaffle.pointsCost;
        if (editingRaffle.skipWeekend !== newRaffle.skipWeekend)
          updates.skipWeekend = newRaffle.skipWeekend;

        // Handle optional fields
        if (editingRaffle.maxTicketsPerUser !== newRaffle.maxTicketsPerUser) {
          updates.maxTicketsPerUser = newRaffle.maxTicketsPerUser || undefined;
        }
        if (editingRaffle.minPointsRequired !== newRaffle.minPointsRequired) {
          updates.minPointsRequired = newRaffle.minPointsRequired || undefined;
        }

        // Compare prize codes
        // Compare prize codes
        const existingCodes = editingRaffle.prizeCodes.map((pc) => pc.code);
        const newCodes = newRaffle.prizeCodes;
        if (JSON.stringify(existingCodes) !== JSON.stringify(newCodes)) {
          updates.prizeCodes = newCodes.map((code) => ({
            id: crypto.randomUUID(),
            code: code.trim(),
            isRevealed: false,
          }));
        }

        if (Object.keys(updates).length > 0) {
          await updateDoc(doc(db, "raffles", editingRaffle.id), updates);
          toast({ title: "ההגרלה עודכנה בהצלחה" });
        }
      } else {
        const raffleData: Partial<Raffle> = {
          title: newRaffle.title,
          description: newRaffle.description,
          date: Timestamp.fromDate(raffleDate),
          endDate: Timestamp.fromDate(endDate),
          scheduleTime: newRaffle.scheduleTime,
          type: newRaffle.type,
          status: newRaffle.status,
          imageUrl: newRaffle.imageUrl,
          pointsCost: newRaffle.pointsCost,
          skipWeekend: newRaffle.skipWeekend,
          totalParticipants: 0,
          participants: [],
          createdAt: Timestamp.now(),
          prizeCodes: newRaffle.prizeCodes.map((code) => ({
            id: crypto.randomUUID(),
            code: code.trim(),
            isRevealed: false,
          })),
        };

        // Add schedule-specific fields based on raffle type
        if (newRaffle.type === "weekly") {
          raffleData.scheduleDay = newRaffle.scheduleDay;
        } else if (newRaffle.type === "monthly") {
          raffleData.scheduleDate = newRaffle.scheduleDate;
        }

        if (newRaffle.maxTicketsPerUser) {
          raffleData.maxTicketsPerUser = newRaffle.maxTicketsPerUser;
        }
        if (newRaffle.minPointsRequired) {
          raffleData.minPointsRequired = newRaffle.minPointsRequired;
        }

        await addDoc(collection(db, "raffles"), raffleData);
        toast({ title: "ההגרלה נוספה בהצלחה" });
      }

      handleCloseModal();
    } catch (error) {
      console.error("Error saving raffle:", error);
      toast({
        title: "שגיאה",
        description: (error as Error).message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
    setShowEditModal(false);
    setEditingRaffle(null);
    setNewRaffle(DEFAULT_NEW_RAFFLE);
    setFormErrors({});
  };

  const renderScheduleFields = () => {
    switch (newRaffle.type) {
      case "daily":
        return (
          <div>
            <Label>שעת הגרלה קבועה</Label>
            <Input
              type="time"
              value={newRaffle.scheduleTime}
              onChange={(e) =>
                setNewRaffle({ ...newRaffle, scheduleTime: e.target.value })
              }
              className={formErrors.scheduleTime ? "border-red-500" : ""}
            />
            {formErrors.scheduleTime && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.scheduleTime}
              </p>
            )}
          </div>
        );

      case "weekly":
        return (
          <div className="space-y-4">
            <div>
              <Label>יום קבוע בשבוע</Label>
              <Select
                value={String(newRaffle.scheduleDay)}
                onValueChange={(value) =>
                  setNewRaffle({ ...newRaffle, scheduleDay: parseInt(value) })
                }
              >
                <SelectTrigger
                  className={formErrors.scheduleDay ? "border-red-500" : ""}
                >
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {DAYS_OF_WEEK.map((day) => (
                    <SelectItem key={day.value} value={String(day.value)}>
                      {day.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {formErrors.scheduleDay && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.scheduleDay}
                </p>
              )}
            </div>

            <div>
              <Label>שעת הגרלה</Label>
              <Input
                type="time"
                value={newRaffle.scheduleTime}
                onChange={(e) =>
                  setNewRaffle({ ...newRaffle, scheduleTime: e.target.value })
                }
                className={formErrors.scheduleTime ? "border-red-500" : ""}
              />
              {formErrors.scheduleTime && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.scheduleTime}
                </p>
              )}
            </div>
          </div>
        );

      case "monthly":
        return (
          <div className="space-y-4">
            <div>
              <Label>תאריך קבוע בחודש</Label>
              <Select
                value={String(newRaffle.scheduleDate)}
                onValueChange={(value) =>
                  setNewRaffle({ ...newRaffle, scheduleDate: parseInt(value) })
                }
              >
                <SelectTrigger
                  className={formErrors.scheduleDate ? "border-red-500" : ""}
                >
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {Array.from({ length: 31 }, (_, i) => i + 1).map((date) => (
                    <SelectItem key={date} value={String(date)}>
                      {date}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {formErrors.scheduleDate && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.scheduleDate}
                </p>
              )}
            </div>

            <div>
              <Label>שעת הגרלה</Label>
              <Input
                type="time"
                value={newRaffle.scheduleTime}
                onChange={(e) =>
                  setNewRaffle({ ...newRaffle, scheduleTime: e.target.value })
                }
                className={formErrors.scheduleTime ? "border-red-500" : ""}
              />
              {formErrors.scheduleTime && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.scheduleTime}
                </p>
              )}
            </div>
          </div>
        );

      default: // oneTime
        return (
          <div className="space-y-4">
            <div>
              <Label>תאריך</Label>
              <Input
                type="date"
                value={newRaffle.date}
                onChange={(e) => handleDateChange(e.target.value, "oneTime")}
                className={formErrors.date ? "border-red-500" : ""}
              />
              {formErrors.date && (
                <p className="text-red-500 text-sm mt-1">{formErrors.date}</p>
              )}
            </div>

            <div>
              <Label>שעת הגרלה</Label>
              <Input
                type="time"
                value={newRaffle.scheduleTime}
                onChange={(e) =>
                  setNewRaffle({ ...newRaffle, scheduleTime: e.target.value })
                }
                className={formErrors.scheduleTime ? "border-red-500" : ""}
              />
              {formErrors.scheduleTime && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.scheduleTime}
                </p>
              )}
            </div>
          </div>
        );
    }
  };
  // המשך מהחלק הקודם...

  const renderForm = () => (
    <div className="space-y-4">
      <div>
        <Label>כותרת</Label>
        <Input
          value={newRaffle.title}
          onChange={(e) => {
            setNewRaffle({ ...newRaffle, title: e.target.value });
            if (formErrors.title) {
              setFormErrors({ ...formErrors, title: undefined });
            }
          }}
          placeholder="הזן כותרת להגרלה"
          className={formErrors.title ? "border-red-500" : ""}
        />
        {formErrors.title && (
          <p className="text-red-500 text-sm mt-1">{formErrors.title}</p>
        )}
      </div>

      <div>
        <Label>תיאור</Label>
        <Input
          value={newRaffle.description}
          onChange={(e) => {
            setNewRaffle({ ...newRaffle, description: e.target.value });
            if (formErrors.description) {
              setFormErrors({ ...formErrors, description: undefined });
            }
          }}
          placeholder="הזן תיאור להגרלה"
          className={formErrors.description ? "border-red-500" : ""}
        />
        {formErrors.description && (
          <p className="text-red-500 text-sm mt-1">{formErrors.description}</p>
        )}
      </div>

      <div>
        <Label>סוג הגרלה</Label>
        <Select
          value={newRaffle.type}
          onValueChange={(value: (typeof RAFFLE_TYPES)[number]["value"]) => {
            setNewRaffle({ ...newRaffle, type: value });
          }}
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {RAFFLE_TYPES.map((type) => (
              <SelectItem key={type.value} value={type.value}>
                {type.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {renderScheduleFields()}

      <div>
        <Label>מחיר בנקודות להשתתפות</Label>
        <div className="flex items-center gap-2">
          <Input
            type="number"
            min="0"
            value={newRaffle.pointsCost}
            onChange={(e) => {
              setNewRaffle({
                ...newRaffle,
                pointsCost: parseInt(e.target.value) || 0,
              });
              if (formErrors.pointsCost) {
                setFormErrors({ ...formErrors, pointsCost: undefined });
              }
            }}
            placeholder="הזן מחיר בנקודות"
            className={formErrors.pointsCost ? "border-red-500" : ""}
          />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Info className="h-4 w-4 text-gray-500" />
              </TooltipTrigger>
              <TooltipContent>
                <p>מספר הנקודות שיש לשלם עבור כרטיס השתתפות בודד</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        {formErrors.pointsCost && (
          <p className="text-red-500 text-sm mt-1">{formErrors.pointsCost}</p>
        )}
      </div>

      {newRaffle.type !== "oneTime" && (
        <div className="flex items-center gap-2">
          <Label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={newRaffle.skipWeekend}
              onChange={(e) =>
                setNewRaffle({ ...newRaffle, skipWeekend: e.target.checked })
              }
              className="h-4 w-4"
            />
            <span>דלג על הגרלות בשישי שבת</span>
          </Label>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Info className="h-4 w-4 text-gray-500" />
              </TooltipTrigger>
              <TooltipContent>
                <p>לא תתקיימנה הגרלות בימי שישי ושבת</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}

      {/* Prize Codes Section */}
      <div>
        <div className="flex items-center justify-between">
          <Label>קודי זכייה</Label>
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() =>
              setNewRaffle((prev) => ({
                ...prev,
                prizeCodes: [...prev.prizeCodes, ""],
              }))
            }
            className="text-xs"
          >
            הוסף קוד +
          </Button>
        </div>

        <div className="space-y-2 mt-2">
          {newRaffle.prizeCodes.map((code, index) => (
            <div key={index} className="flex gap-2">
              <div className="flex-1">
                <Input
                  value={code}
                  onChange={(e) => {
                    const newCodes = [...newRaffle.prizeCodes];
                    newCodes[index] = e.target.value;
                    setNewRaffle({ ...newRaffle, prizeCodes: newCodes });

                    if (formErrors.prizeCodes?.[index]) {
                      const newPrizeCodeErrors = [
                        ...(formErrors.prizeCodes || []),
                      ];
                      newPrizeCodeErrors[index] = "";
                      setFormErrors({
                        ...formErrors,
                        prizeCodes: newPrizeCodeErrors,
                      });
                    }
                  }}
                  placeholder={`קוד זכייה ${index + 1}`}
                  className={
                    formErrors.prizeCodes?.[index] ? "border-red-500" : ""
                  }
                />
                {formErrors.prizeCodes?.[index] && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.prizeCodes[index]}
                  </p>
                )}
              </div>
              {newRaffle.prizeCodes.length > 1 && (
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    const newCodes = newRaffle.prizeCodes.filter(
                      (_, i) => i !== index
                    );
                    setNewRaffle({ ...newRaffle, prizeCodes: newCodes });
                  }}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Image URL */}
      <div>
        <Label>תמונה</Label>
        <Input
          type="url"
          value={newRaffle.imageUrl}
          onChange={(e) => {
            setNewRaffle({ ...newRaffle, imageUrl: e.target.value });
            if (formErrors.imageUrl) {
              setFormErrors({ ...formErrors, imageUrl: undefined });
            }
          }}
          placeholder="הזן קישור לתמונה"
          className={formErrors.imageUrl ? "border-red-500" : ""}
        />
        {formErrors.imageUrl && (
          <p className="text-red-500 text-sm mt-1">{formErrors.imageUrl}</p>
        )}
      </div>
    </div>
  );

  const formatScheduleInfo = (raffle: Raffle) => {
    switch (raffle.type) {
      case "daily":
        return `יומי בשעה ${raffle.scheduleTime}`;
      case "weekly":
        return `${DAYS_OF_WEEK.find((d) => d.value === raffle.scheduleDay)
          ?.label} בשעה ${raffle.scheduleTime}`;
      case "monthly":
        return `${raffle.scheduleDate} לחודש בשעה ${raffle.scheduleTime}`;
      default:
        return raffle.scheduleTime;
    }
  };
  const handleRecurringRaffleReset = async (raffle: Raffle) => {
    try {
      // Reset participants and totalParticipants
      const resetData = {
        participants: [],
        totalParticipants: 0,
      };

      // Add any completed prizes to history if needed
      if (raffle.prizeCodes.some((code) => code.isRevealed)) {
        const completedRaffle = {
          raffleId: raffle.id,
          title: raffle.title,
          date: Timestamp.now(),
          participants: [...raffle.participants],
          prizeCodes: [...raffle.prizeCodes],
        };

        // Save completed raffle to history
        await addDoc(collection(db, "rafflesHistory"), completedRaffle);
      }

      // Reset the raffle for the next round
      await updateDoc(doc(db, "raffles", raffle.id), resetData);

      toast({
        title: "ההגרלה אופסה",
        description: "המשתתפים נמחקו והמערכת מוכנה לסבב הבא",
      });
    } catch (error) {
      console.error("Error resetting raffle:", error);
      toast({
        title: "שגיאה באיפוס ההגרלה",
        description: (error as Error).message,
        variant: "destructive",
      });
    }
  };

  const handleStatusChange = async (
    raffleId: string,
    newStatus: "upcoming" | "active" | "completed"
  ) => {
    try {
      const raffleRef = doc(db, "raffles", raffleId);
      const raffleSnap = await getDoc(raffleRef);
      const raffleData = raffleSnap.data() as Raffle;

      // If the raffle is being completed and it's a recurring type
      if (
        newStatus === "completed" &&
        ["daily", "weekly", "monthly"].includes(raffleData.type)
      ) {
        // First complete the current raffle
        await updateDoc(raffleRef, { status: newStatus });

        // Then reset it for the next round
        await handleRecurringRaffleReset(raffleData);

        // Set status back to upcoming for next round
        await updateDoc(raffleRef, { status: "upcoming" });

        toast({ title: "ההגרלה הושלמה ואופסה לסבב הבא" });
      } else {
        // Regular status update for one-time raffles
        await updateDoc(raffleRef, { status: newStatus });
        toast({ title: "הסטטוס עודכן בהצלחה" });
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast({
        title: "שגיאה בעדכון הסטטוס",
        description: (error as Error).message,
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (id: string) => {
    if (!confirm("האם למחוק את ההגרלה?")) return;

    try {
      await deleteDoc(doc(db, "raffles", id));
      toast({ title: "ההגרלה נמחקה בהצלחה" });
    } catch (error) {
      console.error("Error deleting raffle:", error);
      toast({
        title: "שגיאה במחיקה",
        description: (error as Error).message,
        variant: "destructive",
      });
    }
  };

  const openEditModal = (raffle: Raffle) => {
    const startDate = new Date(raffle.date.seconds * 1000);

    setEditingRaffle(raffle);
    setNewRaffle({
      title: raffle.title,
      description: raffle.description,
      date: startDate.toISOString().split("T")[0],
      endDate: new Date(raffle.endDate.seconds * 1000)
        .toISOString()
        .split("T")[0],
      scheduleTime: raffle.scheduleTime,
      scheduleDay: raffle.scheduleDay,
      scheduleDate: raffle.scheduleDate,
      type: raffle.type,
      status: raffle.status,
      prizeCodes: raffle.prizeCodes.map((pc) => pc.code),
      imageUrl: raffle.imageUrl,
      pointsCost: raffle.pointsCost,
      maxTicketsPerUser: raffle.maxTicketsPerUser,
      minPointsRequired: raffle.minPointsRequired,
      skipWeekend: raffle.skipWeekend ?? false,
    });
    setShowEditModal(true);
  };
  return (
    <div className="p-8">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <h2 className="text-2xl font-bold">ניהול הגרלות</h2>
          <Button onClick={() => setShowAddModal(true)}>הוסף הגרלה חדשה</Button>
        </CardHeader>

        <CardContent>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>כותרת</TableHead>
                  <TableHead>סוג</TableHead>
                  <TableHead>מועד</TableHead>
                  <TableHead>נקודות להשתתפות</TableHead>
                  <TableHead>הגבלות</TableHead>
                  <TableHead>משתתפים</TableHead>
                  <TableHead>סטטוס</TableHead>
                  <TableHead>פעולות</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {raffles.map((raffle) => (
                  <TableRow key={raffle.id}>
                    <TableCell>{raffle.title}</TableCell>
                    <TableCell>
                      {RAFFLE_TYPES.find((t) => t.value === raffle.type)?.label}
                    </TableCell>
                    <TableCell>
                      {raffle.type === "oneTime" ? (
                        formatDate(raffle.date)
                      ) : (
                        <div>
                          <div>{formatScheduleInfo(raffle)}</div>
                          {raffle.skipWeekend && (
                            <div className="text-sm text-gray-500">
                              ללא שישי-שבת
                            </div>
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{raffle.pointsCost} נקודות</TableCell>
                    <TableCell>
                      {raffle.maxTicketsPerUser && (
                        <div>מקס׳ {raffle.maxTicketsPerUser} כרטיסים</div>
                      )}
                      {raffle.minPointsRequired && (
                        <div>מינ׳ {raffle.minPointsRequired} נקודות</div>
                      )}
                    </TableCell>
                    <TableCell>{raffle.totalParticipants}</TableCell>
                    <TableCell>
                      <Select
                        value={raffle.status}
                        onValueChange={(
                          value: "upcoming" | "active" | "completed"
                        ) => handleStatusChange(raffle.id, value)}
                      >
                        <SelectTrigger
                          className={getStatusColor(raffle.status)}
                        >
                          <SelectValue>
                            {getStatusText(raffle.status)}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="upcoming">מתוכננת</SelectItem>
                          <SelectItem value="active">פעילה</SelectItem>
                          <SelectItem value="completed">הסתיימה</SelectItem>
                        </SelectContent>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <div className="flex space-x-2">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => openEditModal(raffle)}
                          disabled={raffle.status === "completed"}
                        >
                          <Edit2 className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleDelete(raffle.id)}
                          disabled={raffle.status === "completed"}
                        >
                          <Trash2 className="h-4 w-4 text-red-600" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <Dialog
            open={showAddModal || showEditModal}
            onOpenChange={handleCloseModal}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  {editingRaffle ? "עריכת הגרלה" : "הוספת הגרלה"}
                </DialogTitle>
              </DialogHeader>
              {renderForm()}

              <DialogFooter>
                <Button
                  variant="outline"
                  onClick={handleCloseModal}
                  disabled={isLoading}
                >
                  ביטול
                </Button>
                <Button onClick={handleSubmit} disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      שומר...
                    </>
                  ) : editingRaffle ? (
                    "עדכן"
                  ) : (
                    "הוסף"
                  )}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </div>
  );
};

export default AdminRaffles;
