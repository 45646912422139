import  { useState } from "react";
import {  ChevronDown, ChevronUp } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";

const CodeRedemptionAlert = () => {
  const [isVisible, ] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  if (!isVisible) return null;

  return (
    <Alert className="relative mb-6 bg-purple-500/10 border border-white/20 backdrop-blur-md rounded-lg">
      <div className="flex items-center justify-between pl-12 pr-4 py-4 flex-row-reverse">
        <AlertTitle className="text-xl font-bold text-white tracking-wide w-full text-center">
          הודעה חשובה – פדיון קודים
        </AlertTitle>

        <Button
          variant="ghost"
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-white hover:bg-purple-500/20 p-2 rounded-lg transition-colors duration-200"
        >
          {isExpanded ? (
            <ChevronUp className="h-5 w-5" />
          ) : (
            <ChevronDown className="h-5 w-5" />
          )}
        </Button>
      </div>

      {isExpanded && (
        <AlertDescription className="text-right px-4 pb-4">
          <div className="space-y-4 text-white/90">
            <p className="text-lg font-bold text-white">
              משתמשים יקרים, שימו לב:
            </p>

            <div className="space-y-3">
              <p className="text-lg font-bold bg-gradient-to-r from-purple-300 to-white bg-clip-text text-transparent">
                טעויות נפוצות:
              </p>
              <p className="text-base leading-relaxed">
                חלק מהמשתמשים מנסים לפדות קודים במקומות הלא נכונים במערכות של
                Epic Games.
              </p>

              <div className="mr-4 space-y-3">
                <p className="font-medium text-white/95">לדוגמה:</p>
                <ul className="list-disc mr-8 space-y-4">
                  <li className="text-base leading-relaxed">
                    קודים עבור{" "}
                    <span className="font-bold text-purple-200">סקינים</span> או{" "}
                    <span className="font-bold text-purple-200">ריקודים</span>{" "}
                    יש לפדות אך ורק דרך האתר:{" "}
                    <a
                      href="https://www.fortnite.com/redeem?lang=en-US"
                      className="text-purple-300 hover:text-purple-200 hover:underline transition-colors duration-200"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.fortnite.com/redeem?lang=en-US
                    </a>
                  </li>
                  <li className="text-base leading-relaxed">
                    קודים עבור{" "}
                    <span className="font-bold text-purple-200">V-Bucks</span>{" "}
                    יש לפדות דרך עמוד ייעודי באתר:{" "}
                    <a
                      href="https://www.fortnite.com/vbuckscard?lang=en-US"
                      className="text-purple-300 hover:text-purple-200 hover:underline transition-colors duration-200"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.fortnite.com/vbuckscard?lang=en-US
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="bg-purple-600/10 p-4 rounded-lg border border-purple-300/20">
              <p className="leading-relaxed">
                <span className="font-bold text-white text-lg">תמיכה:</span>{" "}
                <span className="text-base">
                  במידה ויש בעיה עם קוד, המשתמש מוזמן לפנות לשירות התמיכה, אך
                  אין התחייבות לתיקון הבעיה.
                </span>
              </p>
            </div>
          </div>
        </AlertDescription>
      )}
    </Alert>
  );
};

export default CodeRedemptionAlert;
