import  { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { 
  Table, 
  TableHeader, 
  TableBody, 
  TableRow, 
  TableHead, 
  TableCell 
} from '@/components/ui/table';
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from "@/components/ui/select";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  Timestamp,
  limit
} from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { Users, Activity, Calendar, Award } from 'lucide-react';
import { AnalyticsData } from '@/types';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const AdminAnalytics = () => {
  const [timeRange, setTimeRange] = useState<'day' | 'week' | 'month'>('week');
  const [analytics, setAnalytics] = useState<AnalyticsData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAnalytics();
  }, [timeRange]);

  const fetchAnalytics = async () => {
    setLoading(true);
    try {
      const now = new Date();
      const startDate = new Date();
      
      switch (timeRange) {
        case 'day':
          startDate.setDate(now.getDate() - 1);
          break;
        case 'week':
          startDate.setDate(now.getDate() - 7);
          break;
        case 'month':
          startDate.setMonth(now.getMonth() - 1);
          break;
      }

      // Get users count
      const usersRef = collection(db, 'users');
      const usersSnap = await getDocs(usersRef);
      const totalUsers = usersSnap.size;

      // Get active users
      const activeUsersQuery = query(
        usersRef,
        where('lastLogin', '>=', Timestamp.fromDate(startDate))
      );
      const activeUsersSnap = await getDocs(activeUsersQuery);
      const activeUsers = activeUsersSnap.size;

      // Get login history
      const loginHistoryQuery = query(
        collection(db, 'loginHistory'),
        where('timestamp', '>=', Timestamp.fromDate(startDate)),
        orderBy('timestamp', 'desc')
      );
      const loginHistorySnap = await getDocs(loginHistoryQuery);
      const loginHistory = loginHistorySnap.docs.map(doc => ({
        date: new Date(doc.data().timestamp.seconds * 1000).toLocaleDateString('he-IL'),
        count: doc.data().count
      }));

      // Get recent activity
      const activityQuery = query(
        collection(db, 'activity'),
        orderBy('timestamp', 'desc'),
        limit(10)
      );
      const activitySnap = await getDocs(activityQuery);
      const recentActivity = activitySnap.docs.map(doc => ({
        id: doc.id,
        username: doc.data().username,
        action: doc.data().action,
        timestamp: doc.data().timestamp
      }));

      // Calculate participations
      const participationsQuery = query(
        collection(db, 'participations'),
        where('timestamp', '>=', Timestamp.fromDate(startDate))
      );
      const participationsSnap = await getDocs(participationsQuery);
      const totalParticipations = participationsSnap.size;

      setAnalytics({
        totalUsers,
        activeUsers,
        dailyLogins: loginHistory[0]?.count || 0,
        totalParticipations,
        loginHistory,
        userTypes: [
          { type: 'פעילים', count: activeUsers },
          { type: 'לא פעילים', count: totalUsers - activeUsers }
        ],
        recentActivity
      });
    } catch (error) {
      console.error('Error fetching analytics:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6" dir="rtl">
      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-2xl font-bold">סטטיסטיקות משתמשים</h1>
        <Select 
          value={timeRange} 
          onValueChange={(value: 'day' | 'week' | 'month') => setTimeRange(value)}
        >
          <SelectTrigger className="w-32">
            <SelectValue placeholder="טווח זמן" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="day">24 שעות</SelectItem>
            <SelectItem value="week">שבוע</SelectItem>
            <SelectItem value="month">חודש</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <Card>
          <CardContent className="pt-6">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-sm text-gray-500">סה״כ משתמשים</p>
                <p className="text-2xl font-bold">{analytics?.totalUsers}</p>
              </div>
              <Users className="w-8 h-8 text-blue-500" />
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="pt-6">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-sm text-gray-500">משתמשים פעילים</p>
                <p className="text-2xl font-bold">{analytics?.activeUsers}</p>
              </div>
              <Activity className="w-8 h-8 text-green-500" />
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="pt-6">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-sm text-gray-500">כניסות היום</p>
                <p className="text-2xl font-bold">{analytics?.dailyLogins}</p>
              </div>
              <Calendar className="w-8 h-8 text-purple-500" />
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="pt-6">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-sm text-gray-500">השתתפויות בהגרלות</p>
                <p className="text-2xl font-bold">{analytics?.totalParticipations}</p>
              </div>
              <Award className="w-8 h-8 text-yellow-500" />
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">כניסות משתמשים</h2>
          </CardHeader>
          <CardContent>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={analytics?.loginHistory}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="date" 
                    tick={{ fontSize: 12 }}
                    tickFormatter={(value) => value}
                  />
                  <YAxis />
                  <Tooltip />
                  <Line 
                    type="monotone" 
                    dataKey="count" 
                    stroke="#8884d8" 
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">התפלגות משתמשים</h2>
          </CardHeader>
          <CardContent>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={analytics?.userTypes}
                    dataKey="count"
                    nameKey="type"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label={(entry) => entry.type}
                  >
                    {analytics?.userTypes.map((_, index) => (
                      <Cell 
                        key={`cell-${index}`} 
                        fill={COLORS[index % COLORS.length]} 
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <h2 className="text-lg font-semibold">פעילות אחרונה</h2>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>משתמש</TableHead>
                <TableHead>פעולה</TableHead>
                <TableHead>תאריך</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {analytics?.recentActivity.map((activity) => (
                <TableRow key={activity.id}>
                  <TableCell>{activity.username}</TableCell>
                  <TableCell>{activity.action}</TableCell>
                  <TableCell>
                    {new Date(activity.timestamp.seconds * 1000).toLocaleString('he-IL')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default AdminAnalytics;