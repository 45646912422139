// src/components/layout/Sidebar.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { X, User, Award, Info, LogOut } from 'lucide-react';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
  const { currentUser } = useAuth();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50">
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      />

      {/* Sidebar */}
      <div className="fixed inset-y-0 right-0 w-64 bg-white shadow-xl">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-lg font-bold">תפריט</h2>
          <button onClick={onClose}>
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="py-4">
          {currentUser ? (
            <>
              {/* User Info */}
              <div className="px-4 py-2 border-b">
                <div className="flex items-center space-x-3 space-x-reverse">
                  <img
                    src={currentUser.avatarUrl || '/default-avatar.png'}
                    alt="avatar"
                    className="w-10 h-10 rounded-full"
                  />
                  <div>
                    <p className="font-semibold">{currentUser.username}</p>
                    <p className="text-sm text-gray-500">{currentUser.points} נקודות</p>
                  </div>
                </div>
              </div>

              {/* Menu Items */}
              <div className="px-2 py-2">
                <Link
                  to="/profile"
                  className="flex items-center space-x-2 space-x-reverse px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                  onClick={onClose}
                >
                  <User className="w-5 h-5" />
                  <span>פרופיל</span>
                </Link>

                {currentUser.isAdmin && (
                  <Link
                    to="/admin"
                    className="flex items-center space-x-2 space-x-reverse px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                    onClick={onClose}
                  >
                    <Award className="w-5 h-5" />
                    <span>ניהול</span>
                  </Link>
                )}

                <button
                  onClick={() => {/* Implement logout */}}
                  className="w-full flex items-center space-x-2 space-x-reverse px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                >
                  <LogOut className="w-5 h-5" />
                  <span>התנתק</span>
                </button>
              </div>
            </>
          ) : (
            <div className="px-4">
              <Link
                to="/login"
                className="block w-full text-center bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                onClick={onClose}
              >
                התחבר
              </Link>
            </div>
          )}

          {/* Additional Links */}
          <div className="mt-4 border-t">
            <Link
              to="/about"
              className="flex items-center space-x-2 space-x-reverse px-4 py-2 text-gray-700 hover:bg-gray-100"
              onClick={onClose}
            >
              <Info className="w-5 h-5" />
              <span>אודות</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;