import{ useState, useEffect } from "react";
import { User } from "@/types";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@/components/ui/table";

import {
  collection,
  query,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "@/lib/firebase";
import { Edit2, Trash2, Lock, Unlock } from "lucide-react";
import { useToast } from "@/components/common/Use-toast";
import { DialogHeader, DialogFooter } from "@/components/common/Dialog";
import { Dialog, DialogContent, DialogTitle } from "@radix-ui/react-dialog";
import { Label } from "@/components/common/Label";

interface AdminUser extends User {
  status: "active" | "blocked";
  lastLogin: Date;
  totalParticipations: number;
}

const AdminUsers = () => {
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingUser, setEditingUser] = useState<AdminUser | null>(null);
  const [editForm, setEditForm] = useState({
    username: "",
    email: "",
    points: 0,
    status: "active",
  });
  const { toast } = useToast();

  useEffect(() => {
    const q = query(collection(db, "users"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const usersData: AdminUser[] = [];
      querySnapshot.forEach((doc) => {
        usersData.push({ id: doc.id, ...doc.data() } as AdminUser);
      });
      setUsers(usersData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const filteredUsers = users.filter(
    (user) =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditUser = async () => {
    if (!editingUser) return;

    try {
      await updateDoc(doc(db, "users", editingUser.id), {
        username: editForm.username,
        email: editForm.email,
        points: Number(editForm.points),
        status: editForm.status,
      });

      toast({
        title: "המשתמש עודכן בהצלחה",
        variant: "default",
      });
      setShowEditModal(false);
    } catch (error) {
      toast({
        title: "שגיאה בעדכון המשתמש",
        variant: "destructive",
      });
    }
  };

  const handleDeleteUser = async (userId: string) => {
    if (confirm("האם אתה בטוח שברצונך למחוק את המשתמש?")) {
      try {
        await deleteDoc(doc(db, "users", userId));
        toast({
          title: "המשתמש נמחק בהצלחה",
          variant: "default",
        });
      } catch (error) {
        toast({
          title: "שגיאה במחיקת המשתמש",
          variant: "destructive",
        });
      }
    }
  };

  const handleToggleBlock = async (user: AdminUser) => {
    try {
      const newStatus = user.status === "active" ? "blocked" : "active";
      await updateDoc(doc(db, "users", user.id), {
        status: newStatus,
      });

      toast({
        title: `המשתמש ${newStatus === "active" ? "שוחרר" : "נחסם"} בהצלחה`,
        variant: "default",
      });
    } catch (error) {
      toast({
        title: "שגיאה בעדכון סטטוס המשתמש",
        variant: "destructive",
      });
    }
  };

  const openEditModal = (user: AdminUser) => {
    setEditingUser(user);
    setEditForm({
      username: user.username,
      email: user.email,
      points: user.points,
      status: user.status,
    });
    setShowEditModal(true);
  };

  return (
    <Card className="container mx-auto py-6">
      <CardHeader className="flex flex-row items-center justify-between">
        <h2 className="text-2xl font-bold">ניהול משתמשים</h2>
        <Input
          placeholder="חיפוש משתמשים..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="max-w-xs"
        />
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>שם משתמש</TableHead>
              <TableHead>אימייל</TableHead>
              <TableHead>נקודות</TableHead>
              <TableHead>סטטוס</TableHead>
              <TableHead>כניסה אחרונה</TableHead>
              <TableHead>פעולות</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.points}</TableCell>
                <TableCell>
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${
                      user.status === "active"
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {user.status === "active" ? "פעיל" : "חסום"}
                  </span>
                </TableCell>
                <TableCell>
                  {new Date(user.lastLogin).toLocaleDateString("he-IL")}
                </TableCell>
                <TableCell>
                  <div className="flex space-x-2 space-x-reverse">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => openEditModal(user)}
                    >
                      <Edit2 className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleToggleBlock(user)}
                    >
                      {user.status === "active" ? (
                        <Lock className="h-4 w-4" />
                      ) : (
                        <Unlock className="h-4 w-4" />
                      )}
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleDeleteUser(user.id)}
                      disabled={user.isAdmin}
                    >
                      <Trash2 className="h-4 w-4 text-red-600" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Edit User Modal */}
        <Dialog open={showEditModal} onOpenChange={setShowEditModal}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>עריכת משתמש</DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
              <div>
                <Label htmlFor="edit-username">שם משתמש</Label>
                <Input
                  id="edit-username"
                  value={editForm.username}
                  onChange={(e) =>
                    setEditForm({ ...editForm, username: e.target.value })
                  }
                />
              </div>
              <div>
                <Label htmlFor="edit-email">אימייל</Label>
                <Input
                  id="edit-email"
                  value={editForm.email}
                  onChange={(e) =>
                    setEditForm({ ...editForm, email: e.target.value })
                  }
                />
              </div>
              <div>
                <Label htmlFor="edit-points">נקודות</Label>
                <Input
                  id="edit-points"
                  type="number"
                  value={editForm.points}
                  onChange={(e) =>
                    setEditForm({ ...editForm, points: Number(e.target.value) })
                  }
                />
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setShowEditModal(false)}>
                ביטול
              </Button>
              <Button onClick={handleEditUser}>עדכן</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default AdminUsers;
