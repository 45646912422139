import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "@/lib/firebase";
import {
  User,
  Settings,
  Trophy,
  Gift,
  History,
  Lock,
  Image as ImageIcon,
} from "lucide-react";

interface ProfileStats {
  totalParticipations: number;
  wins: number;
  currentPoints: number;
  totalTickets: number;
}

interface UserActivity {
  id: string;
  type: "participation" | "win" | "points";
  title: string;
  date: Date;
  description: string;
}

interface AvatarOption {
  id: string;
  url: string;
  label: string;
}

interface ProfileUpdateData {
  username?: string;
  email?: string;
  avatarUrl?: string;
}

// Constants
const DEFAULT_STATS: ProfileStats = {
  totalParticipations: 0,
  wins: 0,
  currentPoints: 0,
  totalTickets: 0,
};

const DEFAULT_AVATAR = "/default-avatar.png";

const avatarOptions: AvatarOption[] = [
  { id: "1", url: "/avatars/male1.png", label: "בן - שיער שחור" },
  { id: "2", url: "/avatars/male2.png", label: "בן - ג׳ינג׳י" },
  { id: "3", url: "/avatars/female1.png", label: "בת - שיער שחור" },
  { id: "4", url: "/avatars/female2.png", label: "בת - שיער בלונד" },
];

// Firebase Services
const fetchUserStats = async (userId: string): Promise<ProfileStats> => {
  const submissionsRef = collection(db, "mission_submissions");
  const submissionsQuery = query(
    submissionsRef,
    where("userId", "==", userId),
    where("status", "==", "completed")
  );
  const submissionsSnapshot = await getDocs(submissionsQuery);

  const userDoc = await getDoc(doc(db, "users", userId));
  const userData = userDoc.data();

  let totalParticipations = 0;
  let wins = 0;

  submissionsSnapshot.forEach((doc) => {
    const submission = doc.data();
    totalParticipations++;
    if (submission.type === "win") wins++;
  });

  const ticketsQuery = query(
    collection(db, "tickets"),
    where("userId", "==", userId),
    where("status", "==", "active")
  );
  const ticketsSnapshot = await getDocs(ticketsQuery);

  return {
    totalParticipations,
    wins,
    currentPoints: userData?.points || 0,
    totalTickets: ticketsSnapshot.size,
  };
};

const fetchUserActivities = async (userId: string): Promise<UserActivity[]> => {
  const activitiesRef = collection(db, "user_activities");
  const baseQuery = query(activitiesRef, where("userId", "==", userId));
  const activitiesSnapshot = await getDocs(baseQuery);

  return activitiesSnapshot.docs
    .map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        type: data.type,
        title: data.title,
        description: data.description,
        date: (data.timestamp as Timestamp).toDate(),
      };
    })
    .sort((a, b) => b.date.getTime() - a.date.getTime())
    .slice(0, 10);
};

// Component
const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showAvatarSelector, setShowAvatarSelector] = useState(false);
  const [stats, setStats] = useState<ProfileStats>(DEFAULT_STATS);
  const [activities, setActivities] = useState<UserActivity[]>([]);

  const [formData, setFormData] = useState({
    username: currentUser?.username || "",
    email: currentUser?.email || "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (!currentUser?.id) {
      navigate("/login");
      return;
    }

    const loadUserData = async () => {
      try {
        setLoading(true);
        const [userStats, userActivities] = await Promise.all([
          fetchUserStats(currentUser.id),
          fetchUserActivities(currentUser.id),
        ]);

        setStats(userStats);
        setActivities(userActivities);
      } catch (error) {
        console.error("Error loading user data:", error);
        setError("שגיאה בטעינת נתוני המשתמש");
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, [currentUser?.id, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAvatarSelect = async (avatar: AvatarOption) => {
    if (!currentUser?.id) return;

    try {
      setLoading(true);
      await updateDoc(doc(db, "users", currentUser.id), {
        avatarUrl: avatar.url,
        updatedAt: Timestamp.now(),
      });

      setShowAvatarSelector(false);
      setSuccess("תמונת הפרופיל עודכנה בהצלחה");
    } catch (error) {
      console.error("Error updating avatar:", error);
      setError("שגיאה בעדכון תמונת הפרופיל");
    } finally {
      setLoading(false);
    }
  };

  const handleProfileUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentUser?.id) return;

    if (
      formData.newPassword &&
      formData.newPassword !== formData.confirmPassword
    ) {
      setError("הסיסמאות אינן תואמות");
      return;
    }

    try {
      setLoading(true);
      const updateData: ProfileUpdateData = {
        username: formData.username,
        email: formData.email,
      };

      await updateDoc(doc(db, "users", currentUser.id), {
        ...updateData,
        updatedAt: Timestamp.now(),
      });

      if (formData.newPassword) {
        // TODO: Implement password update logic
      }

      setSuccess("הפרופיל עודכן בהצלחה");
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("שגיאה בעדכון הפרופיל");
    } finally {
      setLoading(false);
    }
  };

  if (!currentUser) return null;

  // Rest of your JSX remains the same...
  return (
    <div className="container mx-auto p-4" dir="rtl">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Profile Card */}
        <Card className="md:col-span-2">
          <CardHeader className="flex flex-row items-center justify-between">
            <h2 className="text-2xl font-bold">פרופיל</h2>
            <Button
              variant="outline"
              onClick={() => setIsEditing(!isEditing)}
              disabled={loading}
            >
              <Settings className="w-4 h-4 ml-2" />
              {isEditing ? "ביטול" : "ערוך פרופיל"}
            </Button>
          </CardHeader>

          <CardContent>
            {error && (
              <Alert variant="destructive" className="mb-4">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {success && (
              <Alert className="mb-4">
                <AlertDescription>{success}</AlertDescription>
              </Alert>
            )}

            <div className="flex items-center space-x-4 space-x-reverse mb-6">
              <div className="relative">
                <img
                  src={currentUser.avatarUrl || DEFAULT_AVATAR}
                  alt="תמונת פרופיל"
                  className="w-20 h-20 rounded-full object-cover"
                />
                <Button
                  size="sm"
                  variant="outline"
                  className="absolute bottom-0 right-0"
                  onClick={() => setShowAvatarSelector(!showAvatarSelector)}
                >
                  <ImageIcon className="w-4 h-4" />
                </Button>
              </div>
              <div>
                <h3 className="text-xl font-semibold">
                  {currentUser.username}
                </h3>
                <p className="text-gray-600">{currentUser.email}</p>
              </div>
            </div>

            {/* Avatar Selector */}
            {showAvatarSelector && (
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-6">
                {avatarOptions.map((avatar) => (
                  <button
                    key={avatar.id}
                    onClick={() => handleAvatarSelect(avatar)}
                    className="relative group"
                    disabled={loading}
                  >
                    <img
                      src={avatar.url}
                      alt={avatar.label}
                      className="w-full rounded-lg transition-transform transform group-hover:scale-105"
                    />
                    <span className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs p-1 text-center">
                      {avatar.label}
                    </span>
                  </button>
                ))}
              </div>
            )}

            {/* Edit Form */}
            {isEditing ? (
              <form onSubmit={handleProfileUpdate} className="space-y-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium">שם משתמש</label>
                  <Input
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    disabled={loading}
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium">אימייל</label>
                  <Input
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled={loading}
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium">סיסמה נוכחית</label>
                  <Input
                    name="currentPassword"
                    type="password"
                    value={formData.currentPassword}
                    onChange={handleInputChange}
                    disabled={loading}
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium">סיסמה חדשה</label>
                  <Input
                    name="newPassword"
                    type="password"
                    value={formData.newPassword}
                    onChange={handleInputChange}
                    disabled={loading}
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium">
                    אימות סיסמה חדשה
                  </label>
                  <Input
                    name="confirmPassword"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    disabled={loading}
                  />
                </div>

                <Button type="submit" disabled={loading}>
                  {loading ? "מעדכן..." : "שמור שינויים"}
                </Button>
              </form>
            ) : (
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <div className="flex items-center space-x-2 space-x-reverse">
                    <Trophy className="w-5 h-5 text-yellow-500" />
                    <span>זכיות: {stats.wins}</span>
                  </div>
                  <div className="flex items-center space-x-2 space-x-reverse">
                    <Gift className="w-5 h-5 text-blue-500" />
                    <span>השתתפויות: {stats.totalParticipations}</span>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center space-x-2 space-x-reverse">
                    <User className="w-5 h-5 text-green-500" />
                    <span>נקודות: {stats.currentPoints}</span>
                  </div>
                  <div className="flex items-center space-x-2 space-x-reverse">
                    <Lock className="w-5 h-5 text-purple-500" />
                    <span>כרטיסים: {stats.totalTickets}</span>
                  </div>
                </div>
              </div>
            )}
          </CardContent>
        </Card>

        {/* Activity History Card */}
        <Card>
          <CardHeader>
            <h2 className="text-xl font-bold">פעילות אחרונה</h2>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {activities.map((activity) => (
                <div
                  key={activity.id}
                  className="border-b pb-4 last:border-0 last:pb-0"
                >
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center space-x-2 space-x-reverse">
                      {activity.type === "win" && (
                        <Trophy className="w-4 h-4 text-yellow-500" />
                      )}
                      {activity.type === "participation" && (
                        <Gift className="w-4 h-4 text-blue-500" />
                      )}
                      {activity.type === "points" && (
                        <History className="w-4 h-4 text-green-500" />
                      )}
                      <span className="font-medium">{activity.title}</span>
                    </div>
                    <span className="text-sm text-gray-500">
                      {new Date(activity.date).toLocaleDateString("he-IL")}
                    </span>
                  </div>
                  <p className="text-sm text-gray-600">
                    {activity.description}
                  </p>
                </div>
              ))}
              {activities.length === 0 && (
                <p className="text-center text-gray-500">אין פעילות להצגה</p>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ProfilePage;
