import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import MobileFooter from "./MobileFooter";
import useShabbatMode from "@/hooks/useShabbatMode";

const Layout: React.FC = () => {
  const { isShabbat } = useShabbatMode();

  if (isShabbat) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-500 via-purple-600 to-pink-500">
        <div className="text-center bg-white/10 backdrop-blur-sm p-8 rounded-lg border border-white/20">
          <h1 className="text-2xl font-bold mb-4 text-white">שבת שלום</h1>
          <p className="text-white/90">האפליקציה תהיה זמינה במוצאי שבת</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-purple-500 via-purple-600 to-pink-500">
      <Navbar />
      <main className="flex-1 container mx-auto px-4 py-8 relative pb-20 md:pb-8">
        {" "}
        {/* הוספת padding-bottom למובייל */}
        {/* אפקט הילה מעל הגרדיאנט */}
        <div className="absolute inset-0 bg-white/5 backdrop-blur-[2px] rounded-2xl" />
        {/* תוכן ראשי */}
        <div className="relative z-10">
          <Outlet />
        </div>
      </main>
      <Footer /> {/* הסתרת הפוטר הרגיל במובייל */}
      <MobileFooter /> {/* הוספת ניווט תחתון למובייל */}
      <Sidebar isOpen={false} onClose={() => {}} />
    </div>
  );
};

export default Layout;
