import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Mail } from "lucide-react";

const AboutPage: React.FC = () => {
  const [showEmail, setShowEmail] = useState(false);

  return (
    <div className="container mx-auto p-4" dir="rtl">
      <Card className="max-w-3xl mx-auto">
        <CardHeader>
          <h1 className="text-3xl font-bold text-center">אודות האפליקציה</h1>
        </CardHeader>

        <CardContent className="space-y-6">
          <section>
            <h2 className="text-xl font-semibold mb-3">מי אנחנו?</h2>
            <p className="text-gray-600">
              אנחנו פלטפורמה המאפשרת למשתמשים להשתתף בהגרלות ולזכות בפרסים
              מעניינים. האפליקציה שלנו נוצרה במטרה לתת הזדמנות שווה לכולם לזכות
              בפרסים איכותיים.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">איך זה עובד?</h2>
            <div className="space-y-3">
              <p className="text-gray-600">
                1. צברו נקודות על ידי השלמת משימות שונות
              </p>
              <p className="text-gray-600">
                2. השתמשו בנקודות כדי להשתתף בהגרלות
              </p>
              <p className="text-gray-600">3. זכו בפרסים מדהימים!</p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">צור קשר</h2>
            <div className="space-y-4">
              <p className="text-gray-600">
                יש לכם שאלות, רעיונות, או בקשות? אנחנו כאן לשירותכם!
              </p>

              <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                <h3 className="font-medium">ניתן לפנות אלינו בנושאים הבאים:</h3>
                <ul className="space-y-2 text-gray-600 pr-4">
                  <li>• שיתופי פעולה ופרסום</li>
                  <li>• תמיכה טכנית ודיווח על באגים</li>
                  <li>• רעיונות חדשים לאפליקציה או בקשות להוספת קודים</li>
                </ul>
                <div className="pt-2 border-t border-gray-200">
                  <div className="flex items-center justify-between">
                    <p className="text-gray-600 font-medium">
                      כתובת המייל שלנו לכל פנייה:
                    </p>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setShowEmail(!showEmail)}
                      className="flex items-center gap-2"
                    >
                      <Mail className="h-4 w-4" />
                      {showEmail ? "הסתר מייל" : "הצג מייל"}
                    </Button>
                  </div>
                  {showEmail && (
                    <p className="mt-2 font-mono text-gray-800 bg-gray-100 p-2 rounded">
                      aAAA023011696@gmail.com
                    </p>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">הצהרה משפטית</h2>
            <p className="text-gray-600">
              האפליקציה אינה קשורה לאפיק גיימס או למשחקים אחרים. כל הסימנים
              המסחריים שייכים לבעליהם המקוריים. אנו לא אחראים לכל נזק או אובדן
              שעלול להיגרם משימוש באפליקציה.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">עקבו אחרינו</h2>
            <div className="flex space-x-4 space-x-reverse">
              <a href="#" className="text-gray-600 hover:text-blue-500">
                דיסקורד
              </a>
              <a href="#" className="text-gray-600 hover:text-red-500">
                יוטיוב
              </a>
              <a href="#" className="text-gray-600 hover:text-pink-500">
                טיקטוק
              </a>
            </div>
          </section>
        </CardContent>
      </Card>
    </div>
  );
};

export default AboutPage;
