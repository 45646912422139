import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/lib/firebase";
;
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTitle } from "@radix-ui/react-dialog";
import { DialogHeader } from "../common/Dialog";
import { useToast } from "../common/Use-toast";


const AVATAR_OPTIONS = [
  { id: 1, url: "/avatars/default1.svg", name: "אווטר 1" },
  { id: 2, url: "/avatars/default2.svg", name: "אווטר 2" },
  { id: 3, url: "/avatars/default3.svg", name: "אווטר 3" },
  { id: 4, url: "/avatars/default4.svg", name: "אווטר 4" },
  { id: 5, url: "/avatars/default5.svg", name: "אווטר 5" },
  { id: 6, url: "/avatars/default6.svg", name: "אווטר 6" },
];

interface UserSetupDialogProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  initialUsername?: string;
}

const UserSetupDialog = ({
  isOpen,
  onClose,
  userId,
  initialUsername,
}: UserSetupDialogProps) => {
  const [username, setUsername] = useState(initialUsername || "");
  const [selectedAvatar, setSelectedAvatar] = useState(AVATAR_OPTIONS[0].url);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await updateDoc(doc(db, "users", userId), {
        username,
        profileImage: selectedAvatar,
        lastUsernameChange: new Date(),
      });

      toast({
        title: "הפרופיל עודכן בהצלחה",
        description: "ברוך הבא לאפליקציה!",
      });

      onClose();
      navigate("/");
    } catch (error) {
      toast({
        title: "שגיאה בעדכון הפרופיל",
        description: "אנא נסה שוב",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle className="text-center">השלמת פרטי פרופיל</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <label className="text-sm font-medium">שם משתמש</label>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="בחר שם משתמש"
              required
              dir="rtl"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">בחר תמונת פרופיל</label>
            <div className="grid grid-cols-3 gap-4">
              {AVATAR_OPTIONS.map((avatar) => (
                <div
                  key={avatar.id}
                  className={`relative cursor-pointer rounded-lg overflow-hidden aspect-square ${
                    selectedAvatar === avatar.url
                      ? "ring-2 ring-blue-500"
                      : "hover:ring-2 hover:ring-gray-300"
                  }`}
                  onClick={() => setSelectedAvatar(avatar.url)}
                >
                  <img
                    src={avatar.url}
                    alt={avatar.name}
                    className="w-full h-full object-cover"
                  />
                  {selectedAvatar === avatar.url && (
                    <div className="absolute inset-0 bg-blue-500 bg-opacity-20" />
                  )}
                </div>
              ))}
            </div>
          </div>

          <Button
            type="submit"
            className="w-full"
            disabled={isLoading || !username.trim()}
          >
            {isLoading ? "מעדכן..." : "שמור והמשך"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UserSetupDialog;