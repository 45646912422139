import React, { createContext, useContext, useState, useEffect } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut as firebaseSignOut,
  User as FirebaseUser,
  UserCredential,
} from "firebase/auth";
import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { auth, db, googleProvider } from "@/lib/firebase";
import { User } from "@/types";

interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  signInWithGoogle: () => Promise<UserCredential>; // Return type is UserCredential
  updateProfile: (data: Partial<User>) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

const convertFirebaseUserToUser = async (
  firebaseUser: FirebaseUser
): Promise<User> => {
  const userDoc = await getDoc(doc(db, "users", firebaseUser.uid));
  const userData = userDoc.data();

  if (!userDoc.exists()) {
    const timestamp = Timestamp.now();
    const defaultUserData = {
      email: firebaseUser.email || "",
      username: firebaseUser.displayName || "משתמש חדש",
      isAdmin: false,
      points: 0,
      avatarUrl: firebaseUser.photoURL || "/default-avatar.png",
      createdAt: timestamp.toDate(),
      lastLogin: timestamp.toDate(),
    };

    await updateDoc(doc(db, "users", firebaseUser.uid), {
      ...defaultUserData,
      createdAt: serverTimestamp(),
      lastLogin: serverTimestamp(),
    });

    return { id: firebaseUser.uid, ...defaultUserData };
  }

  const createdAt = (userData?.createdAt as Timestamp)?.toDate() || new Date();
  const lastLogin = (userData?.lastLogin as Timestamp)?.toDate() || new Date();

  await updateDoc(doc(db, "users", firebaseUser.uid), {
    lastLogin: serverTimestamp(),
  });

  return {
    id: firebaseUser.uid,
    email: firebaseUser.email || "",
    username: userData?.username || firebaseUser.displayName || "משתמש חדש",
    isAdmin: userData?.isAdmin || false,
    points: userData?.points || 0,
    avatarUrl:
      userData?.avatarUrl || firebaseUser.photoURL || "/default-avatar.png",
    createdAt,
    lastLogin,
  };
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          const user = await convertFirebaseUserToUser(firebaseUser);
          setCurrentUser(user);
        } else {
          setCurrentUser(null);
        }
      } catch (error) {
        console.error("Error in auth state change:", error);
        setCurrentUser(null);
      } finally {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const user = await convertFirebaseUserToUser(result.user);
      setCurrentUser(user);
    } catch (error: any) {
      if (error?.code === "auth/user-not-found") {
        throw new Error("משתמש לא קיים במערכת");
      }
      if (error?.code === "auth/wrong-password") {
        throw new Error("סיסמה שגויה");
      }
      throw new Error("התחברות נכשלה");
    }
  };

  const signInWithGoogle = async (): Promise<UserCredential> => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = await convertFirebaseUserToUser(result.user);
      setCurrentUser(user);
      return result; // Important: return the UserCredential
    } catch (error: any) {
      if (error?.code === "auth/popup-closed-by-user") {
        throw new Error("החלון נסגר לפני השלמת ההתחברות");
      }
      if (error?.code === "auth/popup-blocked") {
        throw new Error("החלון נחסם. אנא אפשר חלונות קופצים ונסה שוב");
      }
      throw new Error("התחברות עם Google נכשלה");
    }
  };

  const signOut = async () => {
    try {
      if (currentUser?.id) {
        await updateDoc(doc(db, "users", currentUser.id), {
          lastLogin: serverTimestamp(),
        });
      }
      await firebaseSignOut(auth);
      setCurrentUser(null);
    } catch (error) {
      throw new Error("התנתקות נכשלה");
    }
  };

  const updateProfile = async (data: Partial<User>) => {
    if (!currentUser) throw new Error("משתמש לא מחובר");

    try {
      const userRef = doc(db, "users", currentUser.id);
      await updateDoc(userRef, {
        ...data,
        lastLogin: serverTimestamp(),
      });
      setCurrentUser((prev) => (prev ? { ...prev, ...data } : null));
    } catch (error) {
      throw new Error("עדכון פרופיל נכשל");
    }
  };

  const value: AuthContextType = {
    // Explicitly type the value object
    currentUser,
    loading,
    signIn,
    signOut,
    signInWithGoogle,
    updateProfile,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth חייב להיות בתוך AuthProvider");
  }
  return context;
};
