import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Trash2 } from "lucide-react";
import { Label } from "@/components/common/Label";
import { useToast } from "@/components/common/Use-toast";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  orderBy,
  Timestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "@/lib/firebase";
import { DialogHeader, DialogFooter } from "@/components/common/Dialog";
import { Dialog, DialogContent, DialogTitle } from "@radix-ui/react-dialog";

interface Code {
  id: string;
  code: string;
  giveawayId: string;
  createdAt: Timestamp;
  status: "active" | "used" | "exposed";
  exposedAt: Timestamp | null;
  wonAt: Timestamp | null;
  wonBy: string | null;
  scheduledDate: Timestamp | null;
}

const AdminCodes = () => {
  const [codes, setCodes] = useState<Code[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showBatchModal, setShowBatchModal] = useState(false);
  const [batchCodes, setBatchCodes] = useState("");
  const [newCode, setNewCode] = useState({
    code: "",
    giveawayId: "",
    scheduledDate: null as Date | null,
  });
  const { toast } = useToast();

  useEffect(() => {
    const q = query(
      collection(db, "codes"),
      orderBy("scheduledDate", "desc"),
      orderBy("createdAt", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const codesData: Code[] = [];
      querySnapshot.forEach((doc) => {
        codesData.push({ id: doc.id, ...doc.data() } as Code);
      });
      setCodes(codesData);
    });

    return () => unsubscribe();
  }, []);

  const handleAddCode = async () => {
    if (!newCode.code || !newCode.giveawayId || !newCode.scheduledDate) {
      toast({
        title: "נא למלא את כל השדות",
        variant: "destructive",
      });
      return;
    }

    try {
      await addDoc(collection(db, "codes"), {
        code: newCode.code,
        giveawayId: newCode.giveawayId,
        createdAt: Timestamp.now(),
        status: "active",
        exposedAt: null,
        wonAt: null,
        wonBy: null,
        scheduledDate: Timestamp.fromDate(newCode.scheduledDate),
      });

      toast({
        title: "הקוד נוסף בהצלחה",
        variant: "default",
      });

      setShowAddModal(false);
      setNewCode({ code: "", giveawayId: "", scheduledDate: null });
    } catch (error) {
      toast({
        title: "שגיאה בהוספת הקוד",
        variant: "destructive",
      });
    }
  };

  const handleBatchAdd = async () => {
    if (!newCode.giveawayId) {
      toast({
        title: "נא לבחור הגרלה",
        variant: "destructive",
      });
      return;
    }

    const codes = batchCodes.split("\n").filter((code) => code.trim());
    if (codes.length > 10) {
      toast({
        title: "ניתן להוסיף עד 10 קודים בבת אחת",
        variant: "destructive",
      });
      return;
    }

    try {
      const startDate = new Date();
      for (let i = 0; i < codes.length; i++) {
        const scheduledDate = new Date(startDate);
        scheduledDate.setDate(startDate.getDate() + i);

        await addDoc(collection(db, "codes"), {
          code: codes[i].trim(),
          giveawayId: newCode.giveawayId,
          createdAt: Timestamp.now(),
          status: "active",
          exposedAt: null,
          wonAt: null,
          wonBy: null,
          scheduledDate: Timestamp.fromDate(scheduledDate),
        });
      }

      toast({
        title: `${codes.length} קודים נוספו בהצלחה`,
        variant: "default",
      });

      setShowBatchModal(false);
      setBatchCodes("");
      setNewCode({ code: "", giveawayId: "", scheduledDate: null });
    } catch (error) {
      toast({
        title: "שגיאה בהוספת הקודים",
        variant: "destructive",
      });
    }
  };

  const handleStatusChange = async (
    codeId: string,
    newStatus: Code["status"]
  ) => {
    try {
      await updateDoc(doc(db, "codes", codeId), {
        status: newStatus,
        ...(newStatus === "exposed" ? { exposedAt: Timestamp.now() } : {}),
        ...(newStatus === "used" ? { wonAt: Timestamp.now() } : {}),
      });

      toast({
        title: "הסטטוס עודכן בהצלחה",
        variant: "default",
      });
    } catch (error) {
      toast({
        title: "שגיאה בעדכון הסטטוס",
        variant: "destructive",
      });
    }
  };

  const handleDeleteCode = async (codeId: string) => {
    if (window.confirm("האם אתה בטוח שברצונך למחוק את הקוד?")) {
      try {
        await deleteDoc(doc(db, "codes", codeId));
        toast({
          title: "הקוד נמחק בהצלחה",
          variant: "default",
        });
      } catch (error) {
        toast({
          title: "שגיאה במחיקת הקוד",
          variant: "destructive",
        });
      }
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "active":
        return "text-green-600";
      case "exposed":
        return "text-yellow-600";
      case "used":
        return "text-red-600";
      default:
        return "";
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case "active":
        return "פעיל";
      case "exposed":
        return "נחשף";
      case "used":
        return "נוצל";
      default:
        return "";
    }
  };

  const formatDate = (timestamp: Timestamp) => {
    return new Date(timestamp.seconds * 1000).toLocaleString("he-IL", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };

  return (
    <Card className="container mx-auto py-6">
      <CardHeader className="flex flex-row items-center justify-between">
        <h2 className="text-2xl font-bold">ניהול קודים</h2>
        <div className="flex space-x-2 space-x-reverse">
          <Button variant="outline" onClick={() => setShowAddModal(true)}>
            הוסף קוד בודד
          </Button>
          <Button onClick={() => setShowBatchModal(true)}>
            הוסף קודים להגרלה יומית
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>קוד</TableHead>
                <TableHead>הגרלה</TableHead>
                <TableHead>תאריך חשיפה מתוכנן</TableHead>
                <TableHead>סטטוס</TableHead>
                <TableHead>תאריך חשיפה</TableHead>
                <TableHead>נוצל על ידי</TableHead>
                <TableHead>תאריך ניצול</TableHead>
                <TableHead>פעולות</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {codes.map((code) => (
                <TableRow key={code.id}>
                  <TableCell className="font-medium">{code.code}</TableCell>
                  <TableCell>{code.giveawayId}</TableCell>
                  <TableCell>
                    {code.scheduledDate ? formatDate(code.scheduledDate) : "-"}
                  </TableCell>
                  <TableCell>
                    <Select
                      value={code.status}
                      onValueChange={(value: Code["status"]) =>
                        handleStatusChange(code.id, value)
                      }
                    >
                      <SelectTrigger className={getStatusColor(code.status)}>
                        <SelectValue>{getStatusText(code.status)}</SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="active">פעיל</SelectItem>
                        <SelectItem value="exposed">נחשף</SelectItem>
                        <SelectItem value="used">נוצל</SelectItem>
                      </SelectContent>
                    </Select>
                  </TableCell>
                  <TableCell>
                    {code.exposedAt ? formatDate(code.exposedAt) : "-"}
                  </TableCell>
                  <TableCell>{code.wonBy || "-"}</TableCell>
                  <TableCell>
                    {code.wonAt ? formatDate(code.wonAt) : "-"}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleDeleteCode(code.id)}
                      disabled={code.status !== "active"}
                    >
                      <Trash2 className="h-4 w-4 text-red-600" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        {/* Add Single Code Modal */}
        <Dialog open={showAddModal} onOpenChange={setShowAddModal}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>הוספת קוד חדש</DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
              <div>
                <Label htmlFor="code">קוד</Label>
                <Input
                  id="code"
                  value={newCode.code}
                  onChange={(e) =>
                    setNewCode({ ...newCode, code: e.target.value })
                  }
                  placeholder="הכנס קוד"
                />
              </div>
              <div>
                <Label htmlFor="giveaway">הגרלה</Label>
                <Select
                  value={newCode.giveawayId}
                  onValueChange={(value) =>
                    setNewCode({ ...newCode, giveawayId: value })
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="בחר הגרלה" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="giveaway1">הגרלה 1</SelectItem>
                    <SelectItem value="giveaway2">הגרלה 2</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label htmlFor="scheduledDate">תאריך חשיפה מתוכנן</Label>
                <Input
                  id="scheduledDate"
                  type="date"
                  onChange={(e) =>
                    setNewCode({
                      ...newCode,
                      scheduledDate: e.target.value
                        ? new Date(e.target.value)
                        : null,
                    })
                  }
                />
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setShowAddModal(false)}>
                ביטול
              </Button>
              <Button onClick={handleAddCode}>הוסף</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {/* Batch Add Modal */}
        <Dialog open={showBatchModal} onOpenChange={setShowBatchModal}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>הוספת קודים להגרלה יומית</DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
              <div>
                <Label htmlFor="batchCodes">
                  קודים (עד 10 קודים, כל שורה קוד חדש)
                </Label>
                <Input
                  id="batchCodes"
                  value={batchCodes}
                  onChange={(e) => setBatchCodes(e.target.value)}
                  placeholder="הכנס קודים, כל שורה קוד חדש"
                  className="h-40"
                />
              </div>
              <div>
                <Label htmlFor="giveaway">הגרלה</Label>
                <Select
                  value={newCode.giveawayId}
                  onValueChange={(value) =>
                    setNewCode({ ...newCode, giveawayId: value })
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="בחר הגרלה" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="giveaway1">הגרלה 1</SelectItem>
                    <SelectItem value="giveaway2">הגרלה 2</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => setShowBatchModal(false)}
              >
                ביטול
              </Button>
              <Button onClick={handleBatchAdd}>הוסף</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default AdminCodes;
