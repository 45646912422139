import { useState } from "react";
import { Link } from "react-router-dom";

import { Youtube, Mail, ExternalLink } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../common/Dialog";
import tiktok from "./tik-tok.png";

const Footer = () => {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const quickLinks = [
    { to: "/", label: "דף הבית" },
    { to: "/missions", label: "משימות" },
    { to: "/about", label: "אודות" },
  ];

  const socialLinks = [
    {
      icon: Youtube,
      label: "יוטיוב",
      href: "#",
    },
    {
      icon: tiktok,
      label: "טיקטוק",
      href: "#",
    },
  ];

  return (
    <>
      <footer className="bg-gray-800 text-white py-8 mt-auto" dir="rtl">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Quick Links Section */}
            <div className="space-y-4">
              <h3 className="text-lg font-bold relative after:content-[''] after:absolute after:bottom-0 after:right-0 after:w-16 after:h-0.5 after:bg-blue-400 pb-2">
                קישורים מהירים
              </h3>
              <ul className="space-y-2">
                {quickLinks.map((link) => (
                  <li key={link.to}>
                    <Link
                      to={link.to}
                      className="hover:text-blue-400 transition-colors inline-block"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => setShowTerms(true)}
                    className="hover:text-blue-400 transition-colors"
                  >
                    תנאי שימוש
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => setShowPrivacy(true)}
                    className="hover:text-blue-400 transition-colors"
                  >
                    מדיניות פרטיות
                  </button>
                </li>
              </ul>
            </div>

            {/* Social Links Section */}
            <div className="space-y-4">
              <h3 className="text-lg font-bold relative after:content-[''] after:absolute after:bottom-0 after:right-0 after:w-16 after:h-0.5 after:bg-blue-400 pb-2">
                עקבו אחרינו
              </h3>
              <div className="flex flex-wrap gap-4">
                {socialLinks.map(({ icon: Icon, label, href }) => (
                  <a
                    key={label}
                    href={href}
                    className="flex items-center gap-2 hover:text-blue-400 transition-colors"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {typeof Icon === "string" ? (
                      <img src={Icon} alt={label} className="w-5 h-5" />
                    ) : (
                      <Icon className="w-5 h-5" />
                    )}
                    <span>{label}</span>
                    <ExternalLink className="w-4 h-4" />
                  </a>
                ))}
              </div>
            </div>

            {/* Contact Section */}
            {/* Contact Section */}
            <div className="space-y-4">
              <h3 className="text-lg font-bold relative after:content-[''] after:absolute after:bottom-0 after:right-0 after:w-16 after:h-0.5 after:bg-blue-400 pb-2">
                יצירת קשר
              </h3>
              <div className="space-y-2">
                <a
                  href="mailto:aAAA023011696@gmail.com?subject=פנייה%20חדשה"
                  className="flex items-center gap-2 hover:text-blue-400 transition-colors"
                >
                  <Mail className="w-5 h-5" />
                  <span>שליחת הודעה</span>
                </a>
              </div>
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="mt-8 pt-6 border-t border-gray-700">
            <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
              <div className="flex flex-col sm:flex-row items-center gap-2">
                <p className="text-sm text-gray-400">
                  © 2024 הגרלות ופרסים. כל הזכויות שמורות.
                </p>
                <p className="text-sm text-gray-400">
                  האפליקציה לא קשורה לאפיק גיימס או למשחקים אחרים
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Privacy Policy Modal */}
      <Dialog open={showPrivacy} onOpenChange={setShowPrivacy}>
        <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>מדיניות פרטיות</DialogTitle>
          </DialogHeader>
          <div className="space-y-6 text-right" dir="rtl">
            <section>
              <h2 className="text-xl font-semibold mb-3">איסוף מידע</h2>
              <p className="text-gray-600">
                אנו עשויים לאסוף מידע אישי, כגון: שם משתמש, כתובת אימייל ונתוני
                שימוש באפליקציה, לצורך שיפור השירותים שלנו.
              </p>
              <p className="text-gray-600 mt-2">
                המידע נשמר במערכות מאובטחות ולא מועבר לגורמים חיצוניים למעט
                במקרים הקבועים בחוק.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">שימוש במידע</h2>
              <p className="text-gray-600">המידע ישמש לצורך:</p>
              <ul className="list-disc list-inside mt-2 text-gray-600 mr-4">
                <li>ניהול ותפעול האפליקציה</li>
                <li>שליחת מיילים שיווקיים ועדכונים טכניים</li>
                <li>אכיפת תנאי השימוש ושיפור חוויית המשתמש</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">קבצי עוגיות</h2>
              <p className="text-gray-600">
                אנו משתמשים בקבצי עוגיות לצורך ניתוח ושיפור חוויית המשתמש.
              </p>
            </section>
          </div>
        </DialogContent>
      </Dialog>

      {/* Terms of Service Modal */}
      <Dialog open={showTerms} onOpenChange={setShowTerms}>
        <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>תנאי שימוש</DialogTitle>
          </DialogHeader>
          <div className="space-y-6 text-right" dir="rtl">
            <section>
              <h2 className="text-xl font-semibold mb-3">כללי</h2>
              <p className="text-gray-600">
                השימוש באפליקציה ובשירותיה כפוף לתנאים המפורטים במסמך זה. המשך
                השימוש באפליקציה מהווה הסכמה מפורשת ומלאה לתנאים אלו.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">אחריות על הקודים</h2>
              <p className="text-gray-600">
                הקודים המסופקים נבדקים מראש, אך אין אנו נושאים באחריות מכל סוג
                שהיא, לרבות:
              </p>
              <ul className="list-disc list-inside mt-2 text-gray-600 mr-4">
                <li>הקוד אינו עובד מכל סיבה שהיא</li>
                <li>הקוד נוצל או נפדה על ידי צד שלישי</li>
                <li>הקוד לא נפדה במיקום הנכון בפלטפורמות של Epic Games</li>
                <li>כל תקלה טכנית או אי-התאמה</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">כללי התנהגות</h2>
              <ul className="list-disc list-inside mt-2 text-gray-600 mr-4">
                <li>אין להשתמש בתוכנות אוטומציה או בוטים</li>
                <li>אין ליצור חשבונות מרובים</li>
                <li>אין להעביר או למכור חשבונות</li>
                <li>אין לנצל באגים או פרצות אבטחה</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">סנקציות</h2>
              <ul className="list-disc list-inside mt-2 text-gray-600 mr-4">
                <li>חסימת חשבון לצמיתות</li>
                <li>שלילת זכאות לפרסים</li>
                <li>אפשרות לנקיטת צעדים משפטיים</li>
              </ul>
            </section>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Footer;
