import React, { useState, useEffect } from "react";
import useShabbatMode from "@/hooks/useShabbatMode";
import { X } from "lucide-react";
import { Alert } from "@/components/ui/alert";

interface AdBannerProps {
  type: "opening" | "between-raffles" | "raffle-page";
}

const AdBanner: React.FC<AdBannerProps> = ({ type }) => {
  const { isShabbat } = useShabbatMode();
  const [isVisible, setIsVisible] = useState(true);

  // Check localStorage for opening ad
  useEffect(() => {
    if (type === "opening") {
      const lastSeenTime = localStorage.getItem("lastSeenOpeningAd");
      if (lastSeenTime) {
        const timeDiff = Date.now() - Number(lastSeenTime);
        // Show ad only once every 24 hours
        if (timeDiff < 24 * 60 * 60 * 1000) {
          setIsVisible(false);
        }
      }
    }
  }, [type]);

  // Don't show ads during Shabbat
  if (isShabbat || !isVisible) {
    return null;
  }

  const handleClose = () => {
    setIsVisible(false);
    if (type === "opening") {
      localStorage.setItem("lastSeenOpeningAd", Date.now().toString());
    }
  };

  // Different styles for different ad types
  const adStyles = {
    opening: "fixed inset-0 z-50 bg-white flex items-center justify-center",
    "between-raffles": "w-full h-32 my-4 bg-gray-100 rounded-lg overflow-hidden",
    "raffle-page": "w-full h-24 my-2 bg-gray-100 rounded-lg overflow-hidden",
  };

  // For development/testing - shows which type of ad it is
  const adMessages = {
    opening: "פרסומת פתיחה",
    "between-raffles": "פרסומת בין ההגרלות",
    "raffle-page": "פרסומת בדף ההגרלה",
  };

  return (
    <div className={`${adStyles[type]} relative ad-container`}>
      {/* Close button for opening ad */}
      {type === "opening" && (
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-100 transition-colors"
          aria-label="סגור פרסומת"
        >
          <X className="w-6 h-6 text-gray-600" />
        </button>
      )}

      {/* Ad content */}
      <div className="flex flex-col items-center justify-center w-full h-full p-4">
        {/* This is a placeholder - replace with actual ad content */}
        <div className="text-center">
          <Alert variant="info" className="mb-2">
            <p className="text-sm text-gray-500">זה המקום לפרסומת מסוג:</p>
            <p className="font-bold">{adMessages[type]}</p>
          </Alert>
          
          {/* Ad dimensions info */}
          <div className="text-xs text-gray-400 mt-2">
            {type === "opening" && "מסך מלא"}
            {type === "between-raffles" && "320x100"}
            {type === "raffle-page" && "728x90"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdBanner;
