import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import { he } from "date-fns/locale";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Clock,
  Users,
  Calendar,
  Info,
  Ticket,
  Timer,
  Trophy,
  Star,
  CircleDollarSign,
  Lock,
  Gift,
  AlertCircle,
  CheckCircle2,
  Clock4,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { RaffleData } from "@/types";
import { useAuth } from "@/contexts/AuthContext";

interface RaffleCardProps {
  raffle: RaffleData;
}

const CountdownTimer = ({ startDate }: { startDate: Date }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = startDate.getTime() - now;

      if (distance < 0) {
        clearInterval(timer);
        return;
      }

      setTimeLeft({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [startDate]);

  return (
    <div className="flex justify-center gap-4 text-sm">
      <div className="text-center">
        <div className="bg-gray-100 rounded-lg p-2 font-bold">
          {timeLeft.seconds}
        </div>
        <div className="text-gray-500 mt-1">שניות</div>
      </div>
      <div className="text-center">
        <div className="bg-gray-100 rounded-lg p-2 font-bold">
          {timeLeft.minutes}
        </div>
        <div className="text-gray-500 mt-1">דקות</div>
      </div>
      <div className="text-center">
        <div className="bg-gray-100 rounded-lg p-2 font-bold">
          {timeLeft.hours}
        </div>
        <div className="text-gray-500 mt-1">שעות</div>
      </div>
      <div className="text-center">
        <div className="bg-gray-100 rounded-lg p-2 font-bold">
          {timeLeft.days}
        </div>
        <div className="text-gray-500 mt-1">ימים</div>
      </div>
    </div>
  );
};
const getRaffleTypeText = (type: string) => {
  const types = {
    oneTime: "חד פעמית",
    daily: "יומית",
    weekly: "שבועית",
    monthly: "חודשית",
  };
  return types[type as keyof typeof types] || type;
};

const getStatusStyle = (status: string) => {
  const styles = {
    active: {
      bg: "bg-green-100",
      text: "text-green-800",
      label: "פעיל",
    },
    upcoming: {
      bg: "bg-blue-100",
      text: "text-blue-800",
      label: "עתידי",
    },
    completed: {
      bg: "bg-gray-100",
      text: "text-gray-800",
      label: "הסתיים",
    },
  };
  return styles[status as keyof typeof styles] || styles.completed;
};

const RaffleCard: React.FC<RaffleCardProps> = ({ raffle }) => {
  const { currentUser } = useAuth();
  const endDate =
    typeof raffle.endDate === "string"
      ? new Date(raffle.endDate)
      : new Date(raffle.endDate.seconds * 1000);

  const timeLeft = formatDistance(endDate, new Date(), {
    addSuffix: true,
    locale: he,
  });

  const statusStyle = getStatusStyle(raffle.status);

  const StatusIcon =
    {
      active: CheckCircle2,
      upcoming: Clock4,
      completed: Lock,
    }[raffle.status] || AlertCircle;

  return (
    <Card className="h-full flex flex-col">
      <CardHeader className="relative p-0">
        <img
          src={raffle.imageUrl}
          alt={raffle.title}
          className="w-full h-48 object-cover rounded-t-lg"
        />
        {raffle.status === "completed" && raffle.winner && (
          <div className="absolute inset-0 bg-black/70 flex flex-col items-center justify-center text-white">
            <Gift className="w-12 h-12 mb-2 text-yellow-500" />
            <h3 className="text-xl font-bold mb-1">🎉 הזוכה בהגרלה 🎉</h3>
            <p className="text-lg">{raffle.winner.username}</p>
            {currentUser?.id === raffle.winner.userId && (
              <div className="mt-2 p-2 bg-white/10 rounded">
                <p className="text-sm font-mono">קוד: {raffle.winner.code}</p>
              </div>
            )}
          </div>
        )}
        <div className="absolute top-2 left-2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full text-sm flex items-center">
          <CircleDollarSign className="w-4 h-4 ml-1" />
          {raffle.pointsCost} נקודות
        </div>
        <div
          className={`absolute top-2 right-2 px-3 py-1 rounded-full text-sm ${statusStyle.bg} ${statusStyle.text} flex items-center`}
        >
          <StatusIcon className="w-4 h-4 ml-1" />
          {statusStyle.label}
        </div>
        <div className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full text-sm flex items-center">
          <Calendar className="w-4 h-4 ml-1" />
          {getRaffleTypeText(raffle.type)}
        </div>
        <div className="absolute bottom-2 left-2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full text-sm flex items-center">
          <Gift className="w-4 h-4 ml-1" />
          פרס
        </div>
      </CardHeader>

      <CardContent className="flex-grow p-4">
        <h3 className="text-lg font-bold mb-2 flex items-center gap-2">
          <Trophy className="w-5 h-5 text-yellow-500" />
          {raffle.title}
        </h3>
        <p className="text-gray-600 text-sm mb-4">{raffle.description}</p>

        <div className="mb-4 p-3 bg-gray-50 rounded-lg">
          <h4 className="text-sm font-semibold mb-2 flex items-center gap-2">
            <Info className="w-4 h-4 text-blue-500" />
            דרישות השתתפות
          </h4>
          <div className="space-y-2 text-sm text-gray-600">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center gap-2">
                    <CircleDollarSign className="w-4 h-4 text-green-500" />
                    <span>עלות כניסה: {raffle.pointsCost} נקודות</span>
                  </div>
                </TooltipTrigger>
                <TooltipContent>מחיר ההשתתפות בהגרלה</TooltipContent>
              </Tooltip>
            </TooltipProvider>

            {raffle.minPointsRequired && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="flex items-center gap-2">
                      <Star className="w-4 h-4 text-purple-500" />
                      <span>מינימום: {raffle.minPointsRequired} נקודות</span>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>נדרש מינימום נקודות להשתתפות</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}

            {raffle.maxTicketsPerUser && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="flex items-center gap-2">
                      <Ticket className="w-4 h-4 text-orange-500" />
                      <span>מקסימום {raffle.maxTicketsPerUser} כרטיסים</span>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>מספר כרטיסים מקסימלי למשתתף</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        </div>

        {(raffle.status === "upcoming" || raffle.status === "active") && (
          <div className="mb-4">
            <div className="text-sm font-semibold mb-2 flex items-center gap-2">
              <Timer className="w-4 h-4 text-blue-500" />
              {raffle.status === "upcoming"
                ? "זמן עד לפתיחה"
                : "זמן עד לסיום ההגרלה"}
            </div>
            <CountdownTimer startDate={endDate} />
          </div>
        )}

        <div className="flex items-center justify-between text-sm text-gray-500">
          <div className="flex items-center gap-2">
            <Clock className="w-4 h-4 text-blue-500" />
            <span>{timeLeft}</span>
          </div>
          <div className="flex items-center gap-2">
            <Users className="w-4 h-4 text-green-500" />
            <span>{raffle.totalParticipants} משתתפים</span>
          </div>
        </div>

        {raffle.type !== "oneTime" && (
          <div className="mt-2 flex items-center justify-center gap-2 text-sm text-gray-500">
            <Clock className="w-4 h-4 text-purple-500" />
            <span>זמן הגרלה:</span>
            <span className="font-medium">
              {new Date(`2000-01-01T${raffle.raffleTime}`).toLocaleTimeString(
                "he-IL",
                {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                }
              )}
            </span>
          </div>
        )}
      </CardContent>

      <CardFooter className="p-4 pt-0">
        <Button
          asChild
          className="w-full"
          disabled={raffle.status !== "active"}
        >
          <Link
            to={`/raffle/${raffle.id}`}
            className="flex items-center justify-center gap-2"
          >
            {raffle.status === "active" ? (
              <>
                <Ticket className="w-4 h-4" />
                השתתף בהגרלה
              </>
            ) : raffle.status === "upcoming" ? (
              <>
                <Clock className="w-4 h-4" />
                בקרוב
              </>
            ) : (
              <>
                <Lock className="w-4 h-4" />
                ההגרלה הסתיימה
              </>
            )}
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
};

export default RaffleCard;
