import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  orderBy,
  Timestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "@/lib/firebase";
import { Edit2, Trash2, Loader2 } from "lucide-react";
import { DialogHeader, DialogFooter } from "@/components/common/Dialog";
import { useToast } from "@/components/common/Use-toast";
import { Input } from "@/components/ui/input";
import { Dialog, DialogContent, DialogTitle } from "@radix-ui/react-dialog";
import { Label } from "@/components/common/Label";
import { Textarea } from "@/components/common/Textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Mission,
  MissionType,
  MissionStatus,
  MISSION_STATUSES,
  isVideoMission,
  isCodeMission,
  isSocialMission,
  isUploadMission,
} from "@/types/mission";

interface FormData {
  title: string;
  description: string;
  type: MissionType;
  points: number;
  status: MissionStatus;
  imageUrl: string;
  requirements: string;
  contentInstructions: string;
  maxFileSize: number;
  allowedTypes: string[];
  requiredScreenshots: number;
  videoUrl: string;
  minimumTimeSeconds: number;
  codeInstructions: string;
  validCodes: string[];
  maxAttempts: number;
  platform: string;
  sharingText: string;
  shareLink: string;
}

const MISSION_TYPE_CONFIG = [
  {
    value: "upload" as MissionType,
    label: "העלאת תוכן",
    defaultPoints: 10,
  },
  {
    value: "video" as MissionType,
    label: "צפייה בסרטון",
    defaultPoints: 15,
  },
  {
    value: "code" as MissionType,
    label: "קוד בחנות",
    defaultPoints: 20,
  },
  {
    value: "social" as MissionType,
    label: "שיתוף",
    defaultPoints: 5,
  },
] as const;

const DEFAULT_FORM_DATA: FormData = {
  title: "",
  description: "",
  type: "upload",
  points: 10,
  status: "inactive",
  imageUrl: "",
  requirements: "",
  contentInstructions: "",
  maxFileSize: 5,
  allowedTypes: ["image/jpeg", "image/png"],
  requiredScreenshots: 1,
  videoUrl: "",
  minimumTimeSeconds: 30,
  codeInstructions: "",
  validCodes: [],
  maxAttempts: 3,
  platform: "whatsapp",
  sharingText: "",
  shareLink: "",
};

const AdminMissions = () => {
  const [missions, setMissions] = useState<Mission[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editingMission, setEditingMission] = useState<Mission | null>(null);
  const [formData, setFormData] = useState<FormData>(DEFAULT_FORM_DATA);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const q = query(collection(db, "missions"), orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const missionsData: Mission[] = [];
      querySnapshot.forEach((doc) => {
        missionsData.push({ id: doc.id, ...doc.data() } as Mission);
      });
      setMissions(missionsData);
    });
    return () => unsubscribe();
  }, []);

  const handleStatusChange = async (
    mission: Mission,
    newStatus: MissionStatus
  ) => {
    try {
      await updateDoc(doc(db, "missions", mission.id), {
        status: newStatus,
        updatedAt: Timestamp.now(),
      });
      toast({ title: "הסטטוס עודכן בהצלחה" });
    } catch (error) {
      toast({
        title: "שגיאה בעדכון הסטטוס",
        description: (error as Error).message,
        variant: "destructive",
      });
    }
  };

  const validateForm = (data: FormData): boolean => {
    if (!data.title.trim() || !data.description.trim()) {
      toast({
        title: "שגיאה",
        description: "יש להזין כותרת ותיאור למשימה",
        variant: "destructive",
      });
      return false;
    }

    switch (data.type) {
      case "upload":
        if (!data.contentInstructions.trim()) {
          toast({
            title: "שגיאה",
            description: "יש להזין הוראות למשימה",
            variant: "destructive",
          });
          return false;
        }
        break;

      case "video":
        if (!data.videoUrl.trim()) {
          toast({
            title: "שגיאה",
            description: "יש להזין קישור לסרטון",
            variant: "destructive",
          });
          return false;
        }
        break;

      case "code":
        if (!data.codeInstructions.trim() || data.validCodes.length === 0) {
          toast({
            title: "שגיאה",
            description: "יש להזין הוראות קוד וקודים תקפים",
            variant: "destructive",
          });
          return false;
        }
        break;

      case "social":
        if (!data.sharingText.trim()) {
          toast({
            title: "שגיאה",
            description: "יש להזין טקסט לשיתוף",
            variant: "destructive",
          });
          return false;
        }
        break;
    }

    return true;
  };
  const prepareSubmissionData = (data: FormData) => {
    const baseData = {
      title: data.title,
      description: data.description,
      type: data.type,
      points: data.points,
      status: data.status,
      requirements: data.requirements,
      imageUrl: data.imageUrl,
    };

    switch (data.type) {
      case "upload":
        return {
          ...baseData,
          contentInstructions: data.contentInstructions,
          maxFileSize: data.maxFileSize,
          allowedTypes: data.allowedTypes,
          requiredScreenshots: data.requiredScreenshots,
        };

      case "video":
        return {
          ...baseData,
          videoUrl: data.videoUrl,
          minimumTimeSeconds: data.minimumTimeSeconds,
          watchedTime: 0,
        };

      case "code":
        return {
          ...baseData,
          codeInstructions: data.codeInstructions,
          validCodes: data.validCodes,
          maxAttempts: data.maxAttempts,
          attemptsUsed: 0,
        };

      case "social":
        return {
          ...baseData,
          platform: data.platform,
          shareLink: data.shareLink || window.location.origin,
          sharingText: data.sharingText,
        };

      default:
        return baseData;
    }
  };

  const handleSubmit = async () => {
    if (!validateForm(formData)) return;

    setIsSubmitting(true);
    try {
      const submissionData = prepareSubmissionData(formData);

      if (editingMission) {
        await updateDoc(doc(db, "missions", editingMission.id), {
          ...submissionData,
          updatedAt: Timestamp.now(),
        });
      } else {
        await addDoc(collection(db, "missions"), {
          ...submissionData,
          createdAt: Timestamp.now(),
        });
      }

      toast({
        title: editingMission ? "המשימה עודכנה בהצלחה" : "המשימה נוספה בהצלחה",
      });

      setShowModal(false);
      setEditingMission(null);
      setFormData(DEFAULT_FORM_DATA);
    } catch (error) {
      console.error("Error submitting mission:", error);
      toast({
        title: editingMission ? "שגיאה בעדכון המשימה" : "שגיאה בהוספת המשימה",
        description: (error as Error).message,
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (missionId: string) => {
    if (confirm("האם אתה בטוח שברצונך למחוק את המשימה?")) {
      try {
        await deleteDoc(doc(db, "missions", missionId));
        toast({ title: "המשימה נמחקה בהצלחה" });
      } catch (error) {
        toast({
          title: "שגיאה במחיקת המשימה",
          description: (error as Error).message,
          variant: "destructive",
        });
      }
    }
  };

  const handleEdit = (mission: Mission) => {
    setEditingMission(mission);
    setFormData({
      ...DEFAULT_FORM_DATA,
      ...mission,
      title: mission.title,
      description: mission.description,
      type: mission.type,
      points: mission.points,
      status: mission.status,
      imageUrl: mission.imageUrl || "",
      requirements: mission.requirements || "",
      validCodes: isCodeMission(mission) ? mission.validCodes : [],
      sharingText: isSocialMission(mission) ? mission.sharingText || "" : "",
      videoUrl: isVideoMission(mission) ? mission.videoUrl : "",
      contentInstructions: isUploadMission(mission)
        ? mission.contentInstructions || ""
        : "",
      platform: isSocialMission(mission) ? mission.platform : "whatsapp",
    });
    setShowModal(true);
  };

  const renderTypeSpecificFields = () => {
    switch (formData.type) {
      case "upload":
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="contentInstructions">הוראות משימה</Label>
              <Textarea
                id="contentInstructions"
                value={formData.contentInstructions}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    contentInstructions: e.target.value,
                  })
                }
                placeholder="הכנס תוכן משימה"
                className="min-h-[100px]"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="maxFileSize">גודל קובץ מקסימלי (MB)</Label>
              <Input
                id="maxFileSize"
                type="number"
                min={1}
                value={formData.maxFileSize}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    maxFileSize: Number(e.target.value),
                  })
                }
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="requiredScreenshots">
                מספר צילומי מסך נדרשים
              </Label>
              <Input
                id="requiredScreenshots"
                type="number"
                min={1}
                value={formData.requiredScreenshots}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    requiredScreenshots: Number(e.target.value),
                  })
                }
              />
            </div>
          </div>
        );

      case "video":
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="videoUrl">קישור לסרטון</Label>
              <Input
                id="videoUrl"
                value={formData.videoUrl}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    videoUrl: e.target.value,
                  })
                }
                placeholder="הכנס קישור לסרטון"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="minimumTimeSeconds">
                זמן צפייה מינימלי (שניות)
              </Label>
              <Input
                id="minimumTimeSeconds"
                type="number"
                min={30}
                value={formData.minimumTimeSeconds}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    minimumTimeSeconds: Number(e.target.value),
                  })
                }
              />
            </div>
          </div>
        );

      case "code":
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="codeInstructions">הוראות קוד</Label>
              <Textarea
                id="codeInstructions"
                value={formData.codeInstructions}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    codeInstructions: e.target.value,
                  })
                }
                placeholder="הכנס הוראות לשימוש בקוד"
                className="min-h-[100px]"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="validCodes">קודים תקפים (מופרדים בפסיקים)</Label>
              <Input
                id="validCodes"
                value={formData.validCodes.join(", ")}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    validCodes: e.target.value
                      .split(",")
                      .map((code) => code.trim())
                      .filter(Boolean),
                  })
                }
                placeholder="הכנס קודים תקפים, לדוגמה: קוד1, קוד2, קוד3"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="maxAttempts">מספר נסיונות מקסימלי</Label>
              <Input
                id="maxAttempts"
                type="number"
                min={1}
                value={formData.maxAttempts}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    maxAttempts: Number(e.target.value),
                  })
                }
              />
            </div>
          </div>
        );

      case "social":
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="platform">פלטפורמת שיתוף</Label>
              <Select
                value={formData.platform}
                onValueChange={(value) =>
                  setFormData({
                    ...formData,
                    platform: value,
                  })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="בחר פלטפורמה" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="whatsapp">WhatsApp</SelectItem>
                  <SelectItem value="telegram">Telegram</SelectItem>
                  <SelectItem value="facebook">Facebook</SelectItem>
                  <SelectItem value="instagram">Instagram</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="sharingText">טקסט לשיתוף</Label>
              <Textarea
                id="sharingText"
                value={formData.sharingText}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    sharingText: e.target.value,
                  })
                }
                placeholder="הכנס את הטקסט לשיתוף"
                className="min-h-[100px]"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="shareLink">קישור לשיתוף</Label>
              <Input
                id="shareLink"
                value={formData.shareLink}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    shareLink: e.target.value,
                  })
                }
                placeholder="הכנס קישור לשיתוף"
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Card className="container mx-auto py-6">
      <CardHeader className="flex flex-row items-center justify-between">
        <h2 className="text-2xl font-bold">ניהול משימות</h2>
        <Button
          onClick={() => {
            setEditingMission(null);
            setFormData(DEFAULT_FORM_DATA);
            setShowModal(true);
          }}
        >
          הוסף משימה חדשה
        </Button>
      </CardHeader>

      <CardContent>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>כותרת</TableHead>
                <TableHead>סוג</TableHead>
                <TableHead>נקודות</TableHead>
                <TableHead>סטטוס</TableHead>
                <TableHead>פעולות</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {missions.map((mission) => (
                <TableRow key={mission.id}>
                  <TableCell className="font-medium">
                    <div className="space-y-1">
                      <div>{mission.title}</div>
                      <div className="text-sm text-gray-500">
                        {mission.description}
                      </div>
                      {mission.imageUrl && (
                        <img
                          src={mission.imageUrl}
                          alt="תמונה לדוגמא"
                          className="mt-2 max-w-[100px] h-auto rounded-md"
                          onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.src = "/placeholder-image.jpg";
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    {MISSION_TYPE_CONFIG.find(
                      (type) => type.value === mission.type
                    )?.label || mission.type}
                  </TableCell>
                  <TableCell>{mission.points}</TableCell>
                  <TableCell>
                    <Select
                      value={mission.status}
                      onValueChange={(value: MissionStatus) =>
                        handleStatusChange(mission, value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue>
                          {MISSION_STATUSES[mission.status]}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {Object.entries(MISSION_STATUSES).map(
                          ([key, label]) => (
                            <SelectItem key={key} value={key}>
                              {label}
                            </SelectItem>
                          )
                        )}
                      </SelectContent>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleEdit(mission)}
                      >
                        <Edit2 className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDelete(mission.id)}
                      >
                        <Trash2 className="h-4 w-4 text-red-600" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <Dialog open={showModal} onOpenChange={setShowModal}>
          <DialogContent className="max-w-2xl">
            <DialogHeader>
              <DialogTitle>
                {editingMission ? "עריכת משימה" : "הוספת משימה חדשה"}
              </DialogTitle>
            </DialogHeader>

            <div className="space-y-4 my-4">
              <div className="space-y-2">
                <Label htmlFor="title">כותרת</Label>
                <Input
                  id="title"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  placeholder="הכנס כותרת למשימה"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="description">תיאור</Label>
                <Textarea
                  id="description"
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  placeholder="הכנס תיאור מפורט של המשימה"
                  className="min-h-[100px]"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="type">סוג משימה</Label>
                  <Select
                    value={formData.type}
                    onValueChange={(value: MissionType) => {
                      const selectedType = MISSION_TYPE_CONFIG.find(
                        (type) => type.value === value
                      );
                      if (selectedType) {
                        setFormData({
                          ...DEFAULT_FORM_DATA,
                          type: value,
                          points: selectedType.defaultPoints,
                          title: formData.title,
                          description: formData.description,
                        });
                      }
                    }}
                    disabled={!!editingMission}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="בחר סוג משימה" />
                    </SelectTrigger>
                    <SelectContent>
                      {MISSION_TYPE_CONFIG.map((type) => (
                        <SelectItem key={type.value} value={type.value}>
                          {type.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="points">נקודות</Label>
                  <Input
                    id="points"
                    type="number"
                    min={0}
                    value={formData.points}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        points: Number(e.target.value),
                      })
                    }
                  />
                </div>
              </div>

              {renderTypeSpecificFields()}

              <div className="space-y-2">
                <Label htmlFor="requirements">דרישות נוספות</Label>
                <Textarea
                  id="requirements"
                  value={formData.requirements}
                  onChange={(e) =>
                    setFormData({ ...formData, requirements: e.target.value })
                  }
                  placeholder="הכנס דרישות נוספות למשימה (אופציונלי)"
                  className="min-h-[100px]"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="imageUrl">תמונה (URL)</Label>
                <Input
                  id="imageUrl"
                  value={formData.imageUrl}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      imageUrl: e.target.value,
                    })
                  }
                  placeholder="הכנס קישור לתמונה"
                />
              </div>
            </div>

            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setShowModal(false);
                  setEditingMission(null);
                  setFormData(DEFAULT_FORM_DATA);
                }}
                disabled={isSubmitting}
              >
                ביטול
              </Button>
              <Button onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    {editingMission ? "מעדכן..." : "מוסיף..."}
                  </>
                ) : editingMission ? (
                  "עדכן"
                ) : (
                  "הוסף"
                )}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default AdminMissions;
