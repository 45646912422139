import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { storage, db } from "@/lib/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  addDoc,
  Timestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  Upload,
  Youtube,
  Share2,
  Check,
  Code,
  AlertTriangle,
} from "lucide-react";
import { Progress } from "@/components/ui/progress";
import {
  Mission,
  MissionType,
  MissionStatus,
  isVideoMission,
  isUploadMission,
  isSocialMission,
  isCodeMission,
  MissionCompletionData,
} from "@/types/mission";
import MissionDialog from "@/components/missions/dialog/MissionDialog";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/common/Dialog";
import { toast } from "@/components/common/Use-toast";

// Types
type IconMissionType = Extract<MissionType, "video" | "upload" | "social" | "code">;

interface UploadWarningDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

// Constants
const MISSION_ICONS: Record<IconMissionType, JSX.Element> = {
  video: <Youtube className="w-4 h-4 ml-2" />,
  upload: <Upload className="w-4 h-4 ml-2" />,
  social: <Share2 className="w-4 h-4 ml-2" />,
  code: <Code className="w-4 h-4 ml-2" />,
};

const STATUS_COLORS: Record<MissionStatus, string> = {
  available: "text-blue-500",
  completed: "text-green-500",
  pending: "text-yellow-500",
  locked: "text-gray-500",
  active: "text-green-600",
  inactive: "text-gray-400",
};

// Warning Dialog Component
const UploadWarningDialog: React.FC<UploadWarningDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => (
  <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent className="sm:max-w-md">
      <DialogHeader>
        <DialogTitle className="flex items-center gap-2 text-red-600">
          <AlertTriangle className="w-5 h-5" />
          אזהרה חשובה
        </DialogTitle>
      </DialogHeader>

      <div className="space-y-4">
        <div className="text-center p-4 bg-red-50 rounded-lg border border-red-200">
          <h3 className="font-bold text-red-600 mb-2">⚠️ שימו לב</h3>
          <p className="text-gray-700">
            חל איסור מוחלט על רמאות או שימוש לרעה במערכת. העלאת צילומי מסך
            מזויפים או לא רלוונטיים תגרור חסימה מיידית וביטול כל הנקודות שנצברו.
          </p>
        </div>

        <div className="text-sm text-gray-500">
          בלחיצה על "אני מאשר" אני מצהיר שהתמונה שאני עומד להעלות היא אותנטית
          ועומדת בדרישות המשימה.
        </div>
      </div>

      <DialogFooter className="flex justify-end gap-2">
        <Button variant="outline" onClick={onClose}>
          ביטול
        </Button>
        <Button
          onClick={onConfirm}
          className="bg-red-600 hover:bg-red-700 text-white"
        >
          אני מאשר
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

const MissionsPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [missions, setMissions] = useState<Mission[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);
  const [userPoints, setUserPoints] = useState(0);
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  // Utility Functions
  const getMissionIcon = (type: MissionType): JSX.Element => {
    return MISSION_ICONS[type] || <Code className="w-4 h-4 ml-2" />;
  };

  const getStatusColor = (status: MissionStatus): string => {
    return STATUS_COLORS[status] || "text-gray-500";
  };

  const formatCooldown = (cooldown: Timestamp): string => {
    const now = new Date();
    const cooldownDate = cooldown.toDate();
    const diff = cooldownDate.getTime() - now.getTime();
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours} שעות ${minutes} דקות`;
  };

  const getCooldownProgress = (cooldown: Timestamp): number => {
    const now = new Date();
    const cooldownDate = cooldown.toDate();
    const diff = cooldownDate.getTime() - now.getTime();
    const totalTime = 24 * 60 * 60 * 1000;
    return ((totalTime - diff) / totalTime) * 100;
  };

  const getMissionButtonContent = (mission: Mission): JSX.Element => {
    if (mission.status === "completed") {
      return (
        <>
          <Check className="w-4 h-4 ml-2" />
          הושלם
        </>
      );
    }

    return (
      <>
        {getMissionIcon(mission.type)}
        התחל משימה
      </>
    );
  };

  // Data Functions
  const updatePoints = async (points: number) => {
    if (!currentUser?.id) return;
    
    const userRef = doc(db, "users", currentUser.id);
    await updateDoc(userRef, {
      points: userPoints + points,
    });
    setUserPoints((prev) => prev + points);
  };

  const submitMission = async (
    mission: Mission,
    status: "completed" | "pending",
    additionalData: Record<string, any> = {}
  ) => {
    if (!currentUser?.id) return;

    await addDoc(collection(db, "mission_submissions"), {
      userId: currentUser.id,
      missionId: mission.id,
      type: mission.type,
      status,
      timestamp: Timestamp.now(),
      ...additionalData,
    });

    if (status === "completed") {
      await updatePoints(mission.points);
      toast({
        title: "המשימה הושלמה!",
        description: `קיבלת ${mission.points} נקודות`,
      });
    }
  };

  // Upload Functions


  const handleConfirmUpload = async () => {
    if (!currentUser?.id || !selectedMission || !isUploadMission(selectedMission) || !selectedImage) return;

    try {
      const storageRef = ref(storage, `mission-uploads/${currentUser.id}/${Date.now()}_${selectedImage.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedImage);

      const downloadURL = await new Promise<string>((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => reject(error),
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(url);
            } catch (error) {
              reject(error);
            }
          }
        );
      });

      await submitMission(selectedMission, "pending", { imageUrl: downloadURL });
      setSelectedImage(null);
      setSelectedMission(null);
      await fetchMissions();
    } catch (error) {
      console.error("Error uploading image:", error);
      setError("שגיאה בהעלאת התמונה");
    } finally {
      setShowWarningDialog(false);
    }
  };

  // Mission Completion Handlers
  const handleMissionComplete = async (mission: Mission, data: MissionCompletionData) => {
    if (!currentUser?.id) return;

    try {
      switch (mission.type) {
        case "video":
          if (isVideoMission(mission) && data.watchTime) {
            await submitMission(mission, "completed", {
              watchedTime: data.watchTime,
            });
          }
          break;

        case "upload":
          if (isUploadMission(mission) && data.url) {
            await submitMission(mission, "pending", {
              imageUrl: data.url,
            });
          }
          break;

        case "social":
          if (isSocialMission(mission)) {
            const text = encodeURIComponent(mission.sharingText || "בואו להרוויח פרסים!");
            const url = encodeURIComponent(window.location.origin);
            window.open(`https://wa.me/?text=${text}%20${url}`, "_blank");

            setTimeout(async () => {
              await submitMission(mission, "completed", {
                platform: "whatsapp",
                sharedUrl: window.location.origin,
                sharedText: mission.sharingText,
              });
            }, 1000);
          }
          break;

        case "code":
          if (isCodeMission(mission) && data.code) {
            if (mission.validCodes.includes(data.code)) {
              await submitMission(mission, "completed", { code: data.code });
            } else {
              setError("קוד לא תקין");
              return;
            }
          }
          break;
      }

      setSelectedMission(null);
      await fetchMissions();
    } catch (error) {
      console.error("Error completing mission:", error);
      setError("שגיאה בהשלמת המשימה");
    }
  };

  // Fetch Functions
  const fetchMissions = async () => {
    if (!currentUser?.id) return;

    setLoading(true);
    try {
      const missionsRef = collection(db, "missions");
      const q = query(missionsRef, where("status", "in", ["active", "available"]));
      const querySnapshot = await getDocs(q);

      const missionsData: Mission[] = [];
      for (const doc of querySnapshot.docs) {
        const missionData = { ...doc.data(), id: doc.id } as Mission;

        // Check existing submissions
        const submissionsRef = collection(db, "mission_submissions");
        const submissionsQuery = query(
          submissionsRef,
          where("missionId", "==", doc.id),
          where("userId", "==", currentUser.id),
          where("status", "in", ["completed", "pending"])
        );
        const submissionsSnapshot = await getDocs(submissionsQuery);

        if (!submissionsSnapshot.empty) {
          const submission = submissionsSnapshot.docs[0].data();
          missionData.status = submission.status as MissionStatus;
        }

        missionsData.push(missionData);
      }

      setMissions(missionsData);
    } catch (err) {
      console.error("Error fetching missions:", err);
      setError("שגיאה בטעינת המשימות");
    } finally {
      setLoading(false);
    }
  };

  const fetchUserPoints = async () => {
    if (!currentUser?.id) return;

    try {
      const userDoc = await getDoc(doc(db, "users", currentUser.id));
      if (userDoc.exists()) {
        setUserPoints(userDoc.data().points || 0);
      }
    } catch (error) {
      console.error("Error fetching points:", error);
      setError("שגיאה בטעינת הנקודות");
    }
  };

  // Click Handler
  const handleMissionClick = (mission: Mission) => {
    if (!currentUser) {
      navigate("/login");
      return;
    }
    setSelectedMission(mission);
  };

  // Lifecycle
  useEffect(() => {
    if (currentUser) {
      fetchMissions();
      fetchUserPoints();
    }
  }, [currentUser]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4" dir="rtl">
      <div className="mb-6 flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold mb-2">משימות</h1>
          <p className="text-gray-600">השלם משימות וצבור נקודות!</p>
        </div>
        <div className="text-center">
          <p className="text-lg font-bold">{userPoints}</p>
          <p className="text-sm text-gray-600">נקודות</p>
        </div>
      </div>

      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {missions.map((mission) => (
          <Card key={mission.id}>
            <CardHeader>
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold">{mission.title}</h3>
                <span className={`flex items-center ${getStatusColor(mission.status)}`}>
                  {mission.points} נקודות
                </span>
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600 mb-4">{mission.description}</p>

              {mission.cooldown && (
                <div className="mb-4">
                  <p className="text-sm text-gray-500">
                    זמין שוב בעוד: {formatCooldown(mission.cooldown)}
                  </p>
                  <Progress
                    value={getCooldownProgress(mission.cooldown)}
                    className="mt-2"
                  />
                </div>
              )}

              <Button
                onClick={() => handleMissionClick(mission)}
                disabled={
                  mission.status === "completed" ||
                  mission.status === "locked" ||
                  mission.status === "pending" ||
                  (mission.cooldown && new Date() < mission.cooldown.toDate())
                }
                className="w-full"
              >
                {getMissionButtonContent(mission)}
              </Button>
            </CardContent>
          </Card>
        ))}
      </div>

      {selectedMission && (
        <MissionDialog
          isOpen={!!selectedMission}
          onClose={() => setSelectedMission(null)}
          mission={selectedMission}
          onComplete={handleMissionComplete}
          onCodeSubmit={(code) => handleMissionComplete(selectedMission, { code })}
        />
      )}

      <UploadWarningDialog
        isOpen={showWarningDialog}
        onClose={() => setShowWarningDialog(false)}
        onConfirm={handleConfirmUpload}
      />
    </div>
  );
};

export default MissionsPage;
