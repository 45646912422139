// src/lib/firebase.ts
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  getFirestore,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBFuQmk1ZDqsFMt9MS7AtbkzRhDUVHTTJk",
  authDomain: "v-bucks-lottery.firebaseapp.com",
  projectId: "v-bucks-lottery",
  storageBucket: "v-bucks-lottery.appspot.com",
  messagingSenderId: "156488983226",
  appId: "1:156488983226:web:9b3f0f89bd0a3db7951883",
  measurementId: "G-SKTFMY1951",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

// Authentication providers
import { GoogleAuthProvider } from "firebase/auth";
const googleProvider = new GoogleAuthProvider();

// Custom hook for authentication state
import { useState, useEffect } from "react";
import { User as FirebaseUser } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

interface UserData {
  uid: string;
  email: string | null;
  username: string;
  isAdmin: boolean;
  points: number;
  avatarUrl?: string;
  createdAt: Date;
  lastLogin: Date;
}

const useFirebaseAuth = () => {
  const [user, setUser] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return auth.onAuthStateChanged(
      async (firebaseUser: FirebaseUser | null) => {
        if (firebaseUser) {
          const userDoc = await getDoc(doc(db, "users", firebaseUser.uid));
          if (userDoc.exists()) {
            setUser({
              uid: firebaseUser.uid,
              email: firebaseUser.email,
              ...(userDoc.data() as Omit<UserData, "uid" | "email">),
            });
          } else {
            // Create new user document if it doesn't exist
            const newUser: Omit<UserData, "uid"> = {
              email: firebaseUser.email,
              username: firebaseUser.displayName || "משתמש חדש",
              isAdmin: false,
              points: 0,
              avatarUrl: firebaseUser.photoURL || undefined,
              createdAt: new Date(),
              lastLogin: new Date(),
            };
            // Save to Firestore
            await setDoc(doc(db, "users", firebaseUser.uid), newUser);
            setUser({ uid: firebaseUser.uid, ...newUser });
          }
        } else {
          setUser(null);
        }
        setLoading(false);
      }
    );
  }, []);

  return { user, loading };
};

// Collection references
const usersRef = collection(db, "users");
const rafflesRef = collection(db, "raffles");
const missionsRef = collection(db, "missions");
const codesRef = collection(db, "codes");

// Helper functions
const updateUserPoints = async (userId: string, points: number) => {
  const userRef = doc(db, "users", userId);
  await updateDoc(userRef, {
    points: increment(points),
    lastUpdate: serverTimestamp(),
  });
};

const createRaffle = async (raffleData: any) => {
  const raffleRef = await addDoc(rafflesRef, {
    ...raffleData,
    createdAt: serverTimestamp(),
    status: "upcoming",
  });
  return raffleRef.id;
};

// Export everything we'll need
export {
  auth,
  db,
  analytics,
  googleProvider,
  useFirebaseAuth,
  usersRef,
  rafflesRef,
  missionsRef,
  codesRef,
  storage,
  updateUserPoints,
  createRaffle,
};

// Types
export type { UserData };
