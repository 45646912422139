import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { UserCredential } from "firebase/auth";

import { FcGoogle } from "react-icons/fc";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import UserSetupDialog from "@/components/auth/UserSetupDialog";

const LoginPage = () => {
  const navigate = useNavigate();
  const { signIn, signInWithGoogle } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSetupDialog, setShowSetupDialog] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<string>("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const createUserDocument = async (
    uid: string,
    email: string | null,
    displayName: string | null,
    photoURL: string | null
  ) => {
    try {
      console.log('Creating new user document...', { uid, email });
      
      await setDoc(doc(db, "users", uid), {
        username: displayName || "משתמש חדש",
        email,
        isAdmin: false,
        points: 0,
        createdAt: serverTimestamp(),
        lastLogin: serverTimestamp(),
        lastUpdated: serverTimestamp(),
        lastUsernameChange: serverTimestamp(),
        completedTasks: [],
        wonCodes: [],
        profileImage: photoURL || "/avatars/default1.svg",
        status: "active",
        provider: "google",
        emailVerified: true
      });
      
      console.log('User document created successfully');
    } catch (error) {
      console.error('Error creating user document:', error);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      await signIn(formData.email, formData.password);
      navigate("/");
    } catch (err: any) {
      console.error('Login error:', err);
      const errorMessage = (() => {
        if (err?.code === "auth/invalid-email") {
          return "כתובת האימייל אינה תקינה";
        }
        if (err?.code === "auth/user-disabled") {
          return "המשתמש חסום. אנא פנה לתמיכה";
        }
        if (err?.code === "auth/user-not-found") {
          return "משתמש לא קיים במערכת";
        }
        if (err?.code === "auth/wrong-password") {
          return "סיסמה שגויה";
        }
        return "התחברות נכשלה. אנא בדוק את פרטי ההתחברות ונסה שוב.";
      })();
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setError(null);
    setIsLoading(true);

    try {
      console.log('Starting Google sign in...');
      const result = await signInWithGoogle() as UserCredential;
      
      // Early return if no result
      if (!result) {
        throw new Error('Sign in failed - no result returned');
      }

      // Early return if no user
      if (!result.user) {
        throw new Error('Sign in failed - no user data');
      }

      console.log('Google sign in successful, checking user document...');

      const userDoc = await getDoc(doc(db, "users", result.user.uid));

      if (!userDoc.exists()) {
        console.log('New user - creating document...');
        await createUserDocument(
          result.user.uid,
          result.user.email,
          result.user.displayName,
          result.user.photoURL
        );

        setCurrentUserId(result.user.uid);
        setShowSetupDialog(true);
      } else {
        console.log('Existing user - updating last login...');
        await setDoc(
          doc(db, "users", result.user.uid),
          { 
            lastLogin: serverTimestamp(),
            lastUpdated: serverTimestamp()
          },
          { merge: true }
        );
        navigate("/");
      }
    } catch (err: any) {
      console.error('Google sign in error:', err);
      
      const errorMessage = (() => {
        if (err?.code === "auth/popup-closed-by-user") {
          return "החלון נסגר לפני השלמת ההתחברות";
        }
        if (err?.code === "auth/popup-blocked") {
          return "החלון נחסם. אנא אפשר חלונות קופצים ונסה שוב";
        }
        if (err?.code === "auth/cancelled-popup-request") {
          return "בקשת ההתחברות בוטלה. אנא נסה שוב";
        }
        if (err?.code === "auth/account-exists-with-different-credential") {
          return "קיים כבר חשבון עם אימייל זה. אנא התחבר בדרך אחרת";
        }
        return "התחברות באמצעות Google נכשלה. אנא נסה שוב.";
      })();

      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-purple-500 via-purple-600 to-pink-500"
      dir="rtl"
    >
      <Card className="w-full max-w-md">
        <CardHeader>
          <h2 className="text-center text-2xl font-bold">התחברות</h2>
        </CardHeader>

        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <Input
                type="email"
                name="email"
                placeholder="אימייל"
                value={formData.email}
                onChange={handleInputChange}
                required
                disabled={isLoading}
                className="w-full"
              />
            </div>

            <div>
              <Input
                type="password"
                name="password"
                placeholder="סיסמה"
                value={formData.password}
                onChange={handleInputChange}
                required
                disabled={isLoading}
                className="w-full"
              />
            </div>

            <Button type="submit" disabled={isLoading} className="w-full">
              {isLoading ? "מתחבר..." : "התחבר"}
            </Button>
          </form>

          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">או</span>
            </div>
          </div>

          <Button
            type="button"
            variant="outline"
            onClick={handleGoogleSignIn}
            disabled={isLoading}
            className="w-full"
          >
            <FcGoogle className="w-5 h-5 ml-2" />
            התחבר עם Google
          </Button>
        </CardContent>

        <CardFooter className="text-center">
          <p className="text-sm text-gray-600">
            אין לך חשבון עדיין?{" "}
            <Link
              to="/register"
              className="font-semibold text-blue-600 hover:text-blue-500"
            >
              הירשם כאן
            </Link>
          </p>
        </CardFooter>
      </Card>

      {showSetupDialog && (
        <UserSetupDialog
          isOpen={showSetupDialog}
          onClose={() => {
            setShowSetupDialog(false);
            navigate("/");
          }}
          userId={currentUserId}
          initialUsername=""
        />
      )}
    </div>
  );
};

export default LoginPage;