import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Users, Gift, ListTodo, Key, BarChart, Bell } from "lucide-react";

const AdminPage: React.FC = () => {
  const navItems = [
    { to: "users", icon: Users, label: "משתמשים" },
    { to: "raffles", icon: Gift, label: "הגרלות" },
    { to: "missions", icon: ListTodo, label: "משימות" },
    { to: "codes", icon: Key, label: "קודים" },
    { to: "analytics", icon: BarChart, label: "נתונים" },
    { to: "announcements", icon: Bell, label: "התראות" },
  ];

  return (
    <div className="container mx-auto p-4" dir="rtl">
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-6">
        {/* Sidebar */}
        <Card className="lg:col-span-1 p-4">
          <nav className="space-y-2">
            {navItems.map((item) => (
              <NavLink
                key={item.to}
                to={item.to}
                className={({ isActive }) =>
                  `flex items-center space-x-2 space-x-reverse p-2 rounded-lg transition-colors
                  ${
                    isActive
                      ? "bg-primary text-primary-foreground"
                      : "text-gray-600 hover:bg-gray-100"
                  }`
                }
              >
                <item.icon className="w-5 h-5" />
                <span>{item.label}</span>
              </NavLink>
            ))}
          </nav>
        </Card>

        {/* Main Content */}
        <div className="lg:col-span-5">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
