import { Link, useLocation } from "react-router-dom";
import { Gift, User, Target } from "lucide-react";

const MobileFooter = () => {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 md:hidden">
      {/* Gradient Border on top */}
      <div className="h-[1px] bg-gradient-to-r from-purple-500 via-pink-500 to-blue-500" />

      {/* Footer Navigation */}
      <div className="bg-white/10 backdrop-blur-md border-t border-white/5">
        <div className="flex justify-around items-center h-16 px-6">
          <Link
            to="/profile"
            className="flex flex-col items-center space-y-1 w-16"
          >
            <div
              className={`p-2 rounded-lg transition-all ${
                isActive("/profile")
                  ? "bg-gradient-to-r from-purple-500 to-pink-500"
                  : "text-white/60"
              }`}
            >
              <User className="w-5 h-5" />
            </div>
            <span
              className={`text-xs ${
                isActive("/profile") ? "text-white" : "text-white/60"
              }`}
            >
              פרופיל
            </span>
          </Link>

          <Link
            to="/missions"
            className="flex flex-col items-center space-y-1 w-16"
          >
            <div
              className={`p-2 rounded-lg transition-all ${
                isActive("/missions")
                  ? "bg-gradient-to-r from-purple-500 to-pink-500"
                  : "text-white/60"
              }`}
            >
              <Target className="w-5 h-5" />
            </div>
            <span
              className={`text-xs ${
                isActive("/missions") ? "text-white" : "text-white/60"
              }`}
            >
              משימות
            </span>
          </Link>

          <Link to="/" className="flex flex-col items-center space-y-1 w-16">
            <div
              className={`p-2 rounded-lg transition-all ${
                isActive("/")
                  ? "bg-gradient-to-r from-purple-500 to-pink-500"
                  : "text-white/60"
              }`}
            >
              <Gift className="w-5 h-5" />
            </div>
            <span
              className={`text-xs ${
                isActive("/") ? "text-white" : "text-white/60"
              }`}
            >
              הגרלות
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileFooter;
