import React, { createContext, useState, useEffect } from "react";

type Theme = "light";

interface ThemeContextType {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const ThemeContext = createContext<ThemeContextType | null>(null);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [theme] = useState<Theme>("light");

  useEffect(() => {
    // תמיד שומר light ב-localStorage
    localStorage.setItem("theme", "light");

    // מוודא שאין קלאס dark על המסמך
    document.documentElement.classList.remove("dark");
  }, []);

  const setTheme = () => {
    // פונקציה ריקה כי אנחנו תמיד במצב light
    return;
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
