import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  Timestamp,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { db } from "@/lib/firebase";
import { X } from "lucide-react";
import DOMPurify from "dompurify";

interface Announcement {
  id: string;
  title: string;
  content: string;
  type: "info" | "warning" | "success";
  active: boolean;
  expiresAt: Timestamp | null;
  createdAt: Timestamp;
}

const AnnouncementsBanner = () => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const parseContent = (content: string) => {
    if (!content) return "";

    let parsedContent = content
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace(/\*(.*?)\*/g, "<em>$1</em>")
      .replace(/\_\_(.*?)\_\_/g, "<u>$1</u>")
      .replace(/\#\#(.*?)\#\#/g, '<span class="text-xl">$1</span>')
      .replace(/\#\#\#(.*?)\#\#\#/g, '<span class="text-2xl">$1</span>')
      .replace(/\~\~(.*?)\~\~/g, '<span class="line-through">$1</span>')
      .replace(/\^\^(.*?)\^\^/g, '<span class="text-xs">$1</span>')
      .replace(
        /\{\{red\}\}(.*?)\{\{\/red\}\}/g,
        '<span class="text-red-600">$1</span>'
      )
      .replace(
        /\{\{blue\}\}(.*?)\{\{\/blue\}\}/g,
        '<span class="text-blue-600">$1</span>'
      )
      .replace(
        /\{\{green\}\}(.*?)\{\{\/green\}\}/g,
        '<span class="text-green-600">$1</span>'
      );

    return DOMPurify.sanitize(parsedContent, {
      ALLOWED_TAGS: ["strong", "em", "u", "span", "br"],
      ALLOWED_ATTR: ["class"],
    });
  };

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const now = Timestamp.now();
        let querySnapshot;

        try {
          const q = query(
            collection(db, "announcements"),
            where("active", "==", true),
            where("expiresAt", ">", now),
            orderBy("expiresAt"),
            orderBy("createdAt", "desc")
          );
          querySnapshot = await getDocs(q);
        } catch (indexError) {
          const q = query(
            collection(db, "announcements"),
            where("active", "==", true)
          );
          querySnapshot = await getDocs(q);
        }

        const activeAnnouncements = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }) as Announcement)
          .filter(
            (announcement) =>
              !announcement.expiresAt ||
              announcement.expiresAt.toDate() > new Date()
          )
          .sort((a, b) => {
            const expA = a.expiresAt?.seconds || Number.MAX_SAFE_INTEGER;
            const expB = b.expiresAt?.seconds || Number.MAX_SAFE_INTEGER;
            if (expA !== expB) return expA - expB;
            return (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0);
          });

        setAnnouncements(activeAnnouncements);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  useEffect(() => {
    if (announcements.length <= 1) return;

    const timer = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prev) =>
          prev === announcements.length - 1 ? 0 : prev + 1
        );
        setIsTransitioning(false);
      }, 500);
    }, 5000);

    return () => clearInterval(timer);
  }, [announcements.length]);

  if (!isVisible || announcements.length === 0) {
    return null;
  }

  const currentAnnouncement = announcements[currentIndex];

  const getBannerStyles = (type: string) => {
    switch (type) {
      case "warning":
        return {
          background:
            "bg-gradient-to-r from-yellow-50 to-yellow-100 border-yellow-200",
          text: "text-yellow-800",
        };
      case "success":
        return {
          background:
            "bg-gradient-to-r from-green-50 to-green-100 border-green-200",
          text: "text-green-800",
        };
      default:
        return {
          background:
            "bg-gradient-to-r from-blue-50 to-blue-100 border-blue-200",
          text: "text-blue-800",
        };
    }
  };

  const styles = getBannerStyles(currentAnnouncement.type);

  return (
    <div className={`border-y shadow-sm ${styles.background}`}>
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <div
              className={`text-center transition-all duration-500 ${
                isTransitioning
                  ? "opacity-0 transform -translate-y-2"
                  : "opacity-100 transform translate-y-0"
              } ${styles.text}`}
            >
              <span className="font-semibold mr-2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: parseContent(currentAnnouncement.title),
                  }}
                />
              </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseContent(currentAnnouncement.content),
                }}
              />
            </div>
          </div>
          <button
            onClick={() => setIsVisible(false)}
            className="ml-4 p-1.5 rounded-full hover:bg-white/50 transition-colors"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
        {announcements.length > 1 && (
          <div className="flex justify-center mt-2 gap-2">
            {announcements.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`h-1 transition-all duration-300 rounded-full ${
                  index === currentIndex
                    ? `w-6 ${styles.text}`
                    : `w-3 ${styles.text}/30`
                }`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AnnouncementsBanner;
