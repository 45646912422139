import { Timestamp } from "firebase/firestore";

// Basic Types
export type MissionType = "upload" | "video" | "code" | "social";

export type MissionStatus =
  | "available"
  | "completed"
  | "pending"
  | "locked"
  | "active"
  | "inactive";

export type SubmissionStatus = "pending" | "approved" | "rejected";

// Base Mission Interface
export interface BaseMission {
  id: string;
  title: string;
  description: string;
  type: MissionType;
  points: number;
  status: MissionStatus;
  cooldown?: Timestamp;
  expiresAt?: Timestamp;
  imageUrl?: string;
  requirements?: string;
}

// Specific Mission Types
export interface VideoMission extends BaseMission {
  type: "video";
  videoUrl: string;
  minimumTimeSeconds: number;
  watchedTime?: number;
}

export interface UploadMission extends BaseMission {
  type: "upload";
  maxFileSize?: number;
  allowedTypes?: string[];
  requiredScreenshots?: number;
  contentInstructions?: string;
}

export interface CodeMission extends BaseMission {
  type: "code";
  codeInstructions: string;
  validCodes: string[];
  maxAttempts?: number;
  attemptsUsed?: number;
}

export interface SocialMission extends BaseMission {
  type: "social";
  platform: string;
  shareLink?: string;
  sharingText?: string;
}

// Union Type
export type Mission =
  | VideoMission
  | UploadMission
  | CodeMission
  | SocialMission;

// Form Data Interface
export interface MissionFormData extends BaseMission {
  // Common fields are inherited from BaseMission

  // Video mission fields
  videoUrl?: string;
  minimumTimeSeconds?: number;
  watchedTime?: number;

  // Upload mission fields
  maxFileSize?: number;
  allowedTypes?: string[];
  requiredScreenshots?: number;
  contentInstructions?: string;

  // Code mission fields
  codeInstructions?: string;
  validCodes?: string[];
  maxAttempts?: number;
  attemptsUsed?: number;

  // Social mission fields
  platform?: string;
  shareLink?: string;
  sharingText?: string;
}

// Mission Configuration Type
export interface MissionTypeConfig {
  value: MissionType;
  label: string;
  defaultPoints: number;
  validationRules: {
    requiresInstructions?: boolean;
    requiresUrl?: boolean;
    requiresDuration?: boolean;
    requiresCode?: boolean;
    requiresSocialText?: boolean;
    minDurationSeconds?: number;
    maxAttempts?: number;
  };
}

// Mission Dialog Props Type
export interface MissionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  mission: Mission;
  onComplete: (mission: Mission, data: MissionCompletionData) => Promise<void>;
  onCodeSubmit?: (code: string) => Promise<void>;
}

// Mission Completion Data Type
export interface MissionCompletionData {
  url?: string;
  code?: string;
  progress?: number;
  watchTime?: number;
  submissionTime?: number;
  screenshot?: string;
  content?: string;
  additionalData?: Record<string, any>;
}

// Database Types
export interface MissionSubmission {
  id?: string;
  userId: string;
  missionId: string;
  type: MissionType;
  content: string;
  timestamp: Timestamp;
  status: SubmissionStatus;
  imageUrl?: string;
  code?: string;
  verificationData?: Record<string, any>;
}

export interface MissionUpdate {
  status?: MissionStatus;
  cooldown?: Timestamp;
  points?: number;
  attemptsUsed?: number;
  lastCompletedAt?: Timestamp;
}

export interface MissionProgress {
  missionId: string;
  userId: string;
  completedAt?: Timestamp;
  attempts: number;
  progress?: number;
  lastAttemptAt?: Timestamp;
}

// Constants
export const MISSION_STATUSES: Record<MissionStatus, string> = {
  available: "זמין",
  completed: "הושלם",
  pending: "בבדיקה",
  locked: "נעול",
  active: "פעיל",
  inactive: "לא פעיל",
} as const;

export const MISSION_TYPE_CONFIG: ReadonlyArray<MissionTypeConfig> = [
  {
    value: "upload",
    label: "העלאת תוכן",
    defaultPoints: 10,
    validationRules: {
      requiresInstructions: true,
    },
  },
  {
    value: "video",
    label: "צפייה בסרטון",
    defaultPoints: 15,
    validationRules: {
      requiresUrl: true,
      requiresDuration: true,
      minDurationSeconds: 30,
    },
  },
  {
    value: "code",
    label: "קוד בחנות",
    defaultPoints: 20,
    validationRules: {
      requiresCode: true,
      maxAttempts: 3,
    },
  },
  {
    value: "social",
    label: "שיתוף",
    defaultPoints: 5,
    validationRules: {
      requiresSocialText: true,
    },
  },
] as const;

export const COOLDOWN_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

// Type Guards
export const isVideoMission = (mission: Mission): mission is VideoMission =>
  mission.type === "video";

export const isUploadMission = (mission: Mission): mission is UploadMission =>
  mission.type === "upload";

export const isCodeMission = (mission: Mission): mission is CodeMission =>
  mission.type === "code";

export const isSocialMission = (mission: Mission): mission is SocialMission =>
  mission.type === "social";

// Helper Functions
export const isMissionAvailable = (mission: Mission): boolean => {
  if (mission.status === "locked" || mission.status === "inactive")
    return false;

  if (mission.cooldown) {
    const now = new Date();
    const cooldownDate = mission.cooldown.toDate();
    return now >= cooldownDate;
  }

  if (mission.expiresAt) {
    const now = new Date();
    const expiryDate = mission.expiresAt.toDate();
    return now <= expiryDate;
  }

  return mission.status === "available" || mission.status === "active";
};

export const getMissionRequirements = (mission: Mission): string[] => {
  const requirements: string[] = [];

  switch (mission.type) {
    case "video":
      requirements.push(`צפייה של ${mission.minimumTimeSeconds} שניות`);
      break;
    case "upload":
      if (mission.maxFileSize) {
        requirements.push(`גודל קובץ מקסימלי: ${mission.maxFileSize}MB`);
      }
      if (mission.requiredScreenshots) {
        requirements.push(`נדרש ${mission.requiredScreenshots} צילומי מסך`);
      }
      break;
    case "code":
      requirements.push(mission.codeInstructions);
      break;
    case "social":
      requirements.push(`שיתוף ב${mission.platform}`);
      break;
  }

  if (mission.requirements) {
    requirements.push(mission.requirements);
  }

  return requirements;
};

export const getMissionProgress = (
  mission: Mission,
  progress?: number
): number => {
  if (!progress) return 0;

  switch (mission.type) {
    case "video":
      return (progress / mission.minimumTimeSeconds) * 100;
    case "upload":
      return progress;
    default:
      return 0;
  }
};

export const formatMissionTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const getMissionDeadline = (mission: Mission): Date | null => {
  if (!mission.expiresAt) return null;
  return mission.expiresAt.toDate();
};

export const getRemainingTime = (mission: Mission): number => {
  const deadline = getMissionDeadline(mission);
  if (!deadline) return 0;

  const now = new Date();
  const diff = deadline.getTime() - now.getTime();
  return Math.max(0, Math.floor(diff / 1000));
};
