import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Menu, User, LogOut, Award, X, Target, Info } from "lucide-react";
import { auth } from "@/lib/firebase";
import { signOut } from "firebase/auth";
import { useAuth } from "@/contexts/AuthContext";
import { Gift } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../common/Dropdown-menu";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, loading: authLoading } = useAuth(); // נוסיף את מצב הטעינה מה-context
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const getInitials = (name: string) => {
    return name?.charAt(0).toUpperCase() || "G";
  };

  const getRandomColor = (name: string) => {
    const colors = [
      "bg-purple-500",
      "bg-pink-500",
      "bg-indigo-500",
      "bg-fuchsia-500",
      "bg-violet-500",
      "bg-rose-500",
    ];
    const index = (name?.length || 0) % colors.length;
    return colors[index];
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  };

  const isActiveLink = (path: string) => {
    return location.pathname === path;
  };

  const navLinks = [
    { path: "/", icon: Gift, label: "הגרלות" },
    { path: "/missions", icon: Target, label: "משימות" },
    { path: "/about", icon: Info, label: "אודות" },
  ];
  if (authLoading) {
    return (
      <nav className="sticky top-0 z-50 bg-white/10 backdrop-blur-sm border-b border-white/20">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            {/* רק הלוגו יוצג בזמן הטעינה */}
            <Link
              to="/"
              className="flex items-center gap-2 font-bold text-2xl text-white hover:opacity-80 transition-opacity"
            >
              <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                גיפטקוד<span className="text-white">IL</span>
              </span>
            </Link>
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav className="sticky top-0 z-50 bg-white/10 backdrop-blur-sm border-b border-white/20">
      <div className="container mx-auto px-4">
        {/* Desktop & Mobile Header */}
        <div className="flex justify-between items-center h-16">
          {/* Desktop Logo */}
          <Link
            to="/"
            className="flex items-center gap-2 font-bold text-2xl text-white hover:opacity-80 transition-opacity hidden md:block"
          >
            <div className="relative">
              <div className="absolute -top-1 -right-1 w-3 h-3 bg-blue-500 rounded-full animate-pulse" />
            </div>
            <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              גיפטקוד<span className="text-white">IL</span>
            </span>
          </Link>

          {/* Mobile Layout */}
          <div className="flex items-center justify-between md:hidden w-full">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="focus:outline-none text-white"
            >
              <Menu className="w-6 h-6" />
            </button>

            {/* Centered Mobile Logo */}
            <Link
              to="/"
              className="flex items-center gap-1 font-bold text-xl hover:opacity-80 transition-opacity absolute left-1/2 transform -translate-x-1/2"
            >
              <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                גיפטקוד<span className="text-white">IL</span>
              </span>
            </Link>
            {/* Mobile Profile/Login */}
            <div className="flex items-center">
              {currentUser ? (
                <DropdownMenu dir="rtl">
                  <DropdownMenuTrigger className="focus:outline-none">
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold ${getRandomColor(
                        currentUser.username
                      )}`}
                    >
                      {getInitials(currentUser.username)}
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    align="end"
                    className="w-48 bg-white rounded-md shadow-lg border border-gray-200 p-1"
                  >
                    <DropdownMenuItem className="flex items-center px-3 py-2 hover:bg-gray-100 rounded-sm cursor-pointer text-gray-700">
                      <Link to="/profile" className="flex items-center w-full">
                        <User className="w-4 h-4 ml-2" />
                        פרופיל
                      </Link>
                    </DropdownMenuItem>
                    {currentUser.isAdmin && (
                      <DropdownMenuItem className="flex items-center px-3 py-2 hover:bg-gray-100 rounded-sm cursor-pointer text-gray-700">
                        <Link to="/admin" className="flex items-center w-full">
                          <Award className="w-4 h-4 ml-2" />
                          ניהול
                        </Link>
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : (
                <Link
                  to="/login"
                  className="glass px-4 py-2 rounded-md hover:bg-white/20 transition-colors text-white font-medium"
                >
                  התחבר
                </Link>
              )}
            </div>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-6 space-x-reverse">
            {navLinks.map(({ path, icon: Icon, label }) => (
              <Link
                key={path}
                to={path}
                className={`text-white transition-colors flex items-center space-x-2 space-x-reverse ${
                  isActiveLink(path)
                    ? "text-pink-200 font-medium"
                    : "hover:text-pink-200"
                }`}
              >
                <Icon className="w-4 h-4" />
                <span>{label}</span>
              </Link>
            ))}
          </div>

          {/* Desktop Profile/Login */}
          <div className="hidden md:flex items-center space-x-4 space-x-reverse">
            {currentUser ? (
              <DropdownMenu dir="rtl">
                <DropdownMenuTrigger className="flex items-center focus:outline-none">
                  <div
                    className={`w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold ${getRandomColor(
                      currentUser.username
                    )}`}
                  >
                    {getInitials(currentUser.username)}
                  </div>
                  <span className="text-white text-sm mr-2">
                    {currentUser.username}
                  </span>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="end"
                  className="w-48 bg-white rounded-md shadow-lg border border-gray-200 p-1"
                >
                  <DropdownMenuItem className="flex items-center px-3 py-2 hover:bg-gray-100 rounded-sm cursor-pointer text-gray-700">
                    <Link to="/profile" className="flex items-center w-full">
                      <User className="w-4 h-4 ml-2" />
                      פרופיל
                    </Link>
                  </DropdownMenuItem>
                  {currentUser.isAdmin && (
                    <DropdownMenuItem className="flex items-center px-3 py-2 hover:bg-gray-100 rounded-sm cursor-pointer text-gray-700">
                      <Link to="/admin" className="flex items-center w-full">
                        <Award className="w-4 h-4 ml-2" />
                        ניהול
                      </Link>
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuItem className="flex items-center px-3 py-2 hover:bg-gray-100 rounded-sm cursor-pointer text-red-600">
                    <button
                      onClick={handleLogout}
                      className="flex items-center w-full"
                    >
                      <LogOut className="w-4 h-4 ml-2" />
                      התנתק
                    </button>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <Link
                to="/login"
                className="glass px-4 py-2 rounded-md hover:bg-white/20 transition-colors text-white font-medium"
              >
                התחבר
              </Link>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden fixed inset-0 z-50 bg-white">
            <div className="p-4">
              <div className="flex justify-between items-center mb-8 bg-purple-100 p-4 rounded-lg">
                <h2 className="text-xl font-bold text-gray-800">תפריט</h2>
                <button
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="p-2 bg-purple-200 hover:bg-purple-300 rounded-full text-gray-700"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              <div className="flex flex-col space-y-2">
                {navLinks.map(({ path, icon: Icon, label }) => (
                  <Link
                    key={path}
                    to={path}
                    onClick={() => setIsMobileMenuOpen(false)}
                    className={`flex items-center justify-end text-lg p-3 rounded-lg ${
                      isActiveLink(path)
                        ? "bg-purple-400 text-white font-medium"
                        : "bg-purple-300 text-gray-700 hover:text-purple-700 hover:bg-purple-200"
                    }`}
                  >
                    <span>{label}</span>
                    <Icon className="w-5 h-5 ml-2" />
                  </Link>
                ))}
                {currentUser && (
                  <button
                    onClick={() => {
                      handleLogout();
                      setIsMobileMenuOpen(false);
                    }}
                    className="flex w-full items-center justify-end text-lg p-3 rounded-lg bg-purple-300 text-red-600 hover:text-red-700 hover:bg-red-200"
                  >
                    <span>התנתק</span>
                    <LogOut className="w-5 h-5 ml-2" />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
