import { useState, useEffect, useCallback } from "react";

interface ShabbatTimes {
  isShabbat: boolean;
  nextShabbatStart: Date | null;
  nextShabbatEnd: Date | null;
  timeUntilShabbat: string | null;
  timeUntilShabbatEnd: string | null;
}

const useShabbatMode = () => {
  const [shabbatTimes, setShabbatTimes] = useState<ShabbatTimes>({
    isShabbat: false,
    nextShabbatStart: null,
    nextShabbatEnd: null,
    timeUntilShabbat: null,
    timeUntilShabbatEnd: null,
  });

  // Helper function to get Israel time
  const getIsraelTime = useCallback(() => {
    return new Date(
      new Date().toLocaleString("en-US", { timeZone: "Asia/Jerusalem" })
    );
  }, []);

  // Helper function to format time difference
  const formatTimeDifference = useCallback(
    (targetDate: Date) => {
      const now = getIsraelTime();
      const diff = targetDate.getTime() - now.getTime();

      if (diff < 0) return null;

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

      if (hours >= 24) {
        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;
        return `${days} ימים, ${remainingHours} שעות, ${minutes} דקות`;
      }

      return `${hours} שעות, ${minutes} דקות`;
    },
    [getIsraelTime]
  );

  // Calculate next Shabbat times
  const calculateNextShabbatTimes = useCallback(() => {
    const israelTime = getIsraelTime();
    const currentDay = israelTime.getDay();

    // Calculate days until next Friday
    const daysUntilFriday = (5 - currentDay + 7) % 7;

    // Create next Shabbat start date (Friday 16:00)
    const nextStart = new Date(israelTime);
    nextStart.setDate(israelTime.getDate() + daysUntilFriday);
    nextStart.setHours(16, 0, 0, 0);

    // Create next Shabbat end date (Saturday 21:00)
    const nextEnd = new Date(nextStart);
    nextEnd.setDate(nextStart.getDate() + 1);
    nextEnd.setHours(21, 0, 0, 0);

    return { nextStart, nextEnd };
  }, [getIsraelTime]);

  // Check if current time is during Shabbat
  const checkShabbat = useCallback(() => {
    const israelTime = getIsraelTime();
    const day = israelTime.getDay();
    const hours = israelTime.getHours();
    const minutes = israelTime.getMinutes();

    const isShabbat =
      (day === 5 && hours >= 16) || // Friday after 16:00
      (day === 6 && (hours < 20 || (hours === 20 && minutes === 0))); // Saturday before/at 21:00

    const { nextStart, nextEnd } = calculateNextShabbatTimes();

    setShabbatTimes({
      isShabbat,
      nextShabbatStart: nextStart,
      nextShabbatEnd: nextEnd,
      timeUntilShabbat: !isShabbat ? formatTimeDifference(nextStart) : null,
      timeUntilShabbatEnd: isShabbat ? formatTimeDifference(nextEnd) : null,
    });
  }, [getIsraelTime, calculateNextShabbatTimes, formatTimeDifference]);

  useEffect(() => {
    // Check immediately
    checkShabbat();

    // Check every minute
    const interval = setInterval(checkShabbat, 60000);

    return () => clearInterval(interval);
  }, [checkShabbat]);

  // Helper function to check if a specific date falls during Shabbat
  const isDateDuringShabbat = useCallback((date: Date) => {
    const israelDate = new Date(
      date.toLocaleString("en-US", { timeZone: "Asia/Jerusalem" })
    );
    const day = israelDate.getDay();
    const hours = israelDate.getHours();

    return (
      (day === 5 && hours >= 16) || // Friday after 16:00
      (day === 6 && hours < 21) // Saturday before 21:00
    );
  }, []);

  // Helper function to get next available non-Shabbat date
  const getNextAvailableDate = useCallback(
    (date: Date): Date => {
      const nextDate = new Date(date);

      while (isDateDuringShabbat(nextDate)) {
        nextDate.setDate(nextDate.getDate() + 1);
        nextDate.setHours(21, 0, 0, 0); // Set to 21:00
      }

      return nextDate;
    },
    [isDateDuringShabbat]
  );

  return {
    ...shabbatTimes,
    isDateDuringShabbat,
    getNextAvailableDate,
  };
};

export default useShabbatMode;
