import { useState, useEffect } from "react";
import { DialogHeader, DialogFooter } from "@/components/common/Dialog";
import { Dialog, DialogContent, DialogTitle } from "@radix-ui/react-dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/common/Label";
import { Progress } from "@/components/ui/progress";
import {
  Youtube,
  Share2,
  Check,
  Code,
  Loader2,
  XCircle,
  Upload,
} from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  MissionCompletionData,
  MissionDialogProps,
  isVideoMission,
  isUploadMission,
  isCodeMission,
  isSocialMission,
  isMissionAvailable,
  getMissionRequirements,
} from "@/types/mission";
import { db } from "@/lib/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { useAuth } from "@/contexts/AuthContext";
import { v4 as uuidv4 } from "uuid";

const MissionDialog: React.FC<MissionDialogProps> = ({
  isOpen,
  onClose,
  mission,
  onComplete,

}) => {
  if (!mission) return null;

  const [error, setError] = useState<string | null>(null);
  const [code, setCode] = useState("");
  const [isWatching, setIsWatching] = useState(false);
  const [watchTime, setWatchTime] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [requirements, setRequirements] = useState<string[]>([]);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (mission) {
      const reqs = getMissionRequirements(mission);
      setRequirements(reqs);
    }
  }, [mission]);

  useEffect(() => {
    let timer: number;
    if (isWatching && isVideoMission(mission)) {
      timer = window.setInterval(() => {
        setWatchTime((prev) => prev + 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isWatching, mission]);

  const resetState = () => {
    setError(null);
    setCode("");
    setIsWatching(false);
    setWatchTime(0);
    setIsSubmitting(false);
    setImageFile(null);
    setUploadProgress(0);
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const uploadImage = async (file: File): Promise<string> => {
    if (!currentUser?.id) throw new Error("משתמש לא מחובר");

    const storage = getStorage();
    const fileExt = file.name.split(".").pop();
    const fileName = `mission-uploads/${currentUser.id}/${uuidv4()}.${fileExt}`;
    const storageRef = ref(storage, fileName);

    try {
      setIsUploading(true);
      setUploadProgress(0);

      const uploadTask = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(uploadTask.ref);

      setUploadProgress(100);
      return downloadURL;
    } finally {
      setIsUploading(false);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (isUploadMission(mission)) {
      // בדיקת גודל קובץ
      if (
        mission.maxFileSize &&
        file.size > mission.maxFileSize * 1024 * 1024
      ) {
        setError(`גודל הקובץ חייב להיות קטן מ-${mission.maxFileSize}MB`);
        return;
      }

      // בדיקת סוג קובץ
      if (
        mission.allowedTypes?.length &&
        !mission.allowedTypes.includes(file.type)
      ) {
        setError("סוג הקובץ אינו נתמך");
        return;
      }
    }

    setImageFile(file);
    setError(null);
  };

  const addUserActivity = async (activityData: {
    type: "completion";
    missionId: string;
    points: number;
  }) => {
    if (!currentUser?.id) return;

    try {
      await addDoc(collection(db, "user_activities"), {
        userId: currentUser.id,
        ...activityData,
        timestamp: Timestamp.now(),
      });
    } catch (error) {
      console.error("Error adding user activity:", error);
    }
  };

  const handleComplete = async () => {
    if (!currentUser?.id || isSubmitting || !isMissionAvailable(mission))
      return;

    try {
      setIsSubmitting(true);
      setError(null);

      const completionData: MissionCompletionData = {};

      switch (mission.type) {
        case "video":
          if (watchTime < mission.minimumTimeSeconds) {
            setError(`יש לצפות לפחות ${mission.minimumTimeSeconds} שניות`);
            return;
          }
          completionData.watchTime = watchTime;
          break;

        case "upload":
          if (!imageFile) {
            setError("יש לבחור תמונה להעלאה");
            return;
          }

          const imageUrl = await uploadImage(imageFile);
          completionData.url = imageUrl;
          break;

        case "code":
          if (!code.trim()) {
            setError("יש להזין קוד");
            return;
          }
          completionData.code = code;
          break;

        case "social":
          completionData.content = "shared";
          break;
      }

      await onComplete(mission, completionData);
      await addUserActivity({
        type: "completion",
        missionId: mission.id,
        points: mission.points,
      });

      handleClose();
    } catch (error) {
      setError(error instanceof Error ? error.message : "שגיאה בהשלמת המשימה");
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderMissionContent = () => {
    if (isVideoMission(mission)) {
      return (
        <div className="space-y-4">
          <div className="aspect-video bg-black rounded-lg overflow-hidden">
            {isWatching ? (
              <iframe
                src={mission.videoUrl}
                className="w-full h-full"
                allowFullScreen
              />
            ) : (
              <div className="flex flex-col items-center justify-center h-full bg-gray-100">
                <Youtube className="w-12 h-12 text-gray-400 mb-2" />
                <Button onClick={() => setIsWatching(true)}>התחל צפייה</Button>
              </div>
            )}
          </div>
          {mission.minimumTimeSeconds > 0 && (
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span>זמן צפייה</span>
                <span>
                  {watchTime} / {mission.minimumTimeSeconds} שניות
                </span>
              </div>
              <Progress
                value={(watchTime / mission.minimumTimeSeconds) * 100}
              />
            </div>
          )}
        </div>
      );
    }

    if (isUploadMission(mission)) {
      return (
        <div className="space-y-4">
          <div className="border-2 border-dashed border-gray-200 rounded-lg p-4">
            <Label className="block mb-2">העלאת תמונה</Label>
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              disabled={isSubmitting || isUploading}
            />

            {imageFile && (
              <div className="mt-2 relative">
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt="תצוגה מקדימה"
                  className="max-h-40 rounded-md"
                />
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute top-1 right-1"
                  onClick={() => setImageFile(null)}
                >
                  <XCircle className="h-5 w-5 text-red-500" />
                </Button>
              </div>
            )}

            {isUploading && (
              <Progress value={uploadProgress} className="w-full mt-2" />
            )}

            {mission.maxFileSize && (
              <p className="text-sm text-gray-600 mt-2">
                גודל קובץ מקסימלי: {mission.maxFileSize}MB
              </p>
            )}
          </div>
        </div>
      );
    }

    if (isCodeMission(mission)) {
      return (
        <div className="space-y-2">
          <Label>הזן קוד:</Label>
          <Input
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="הכנס את הקוד כאן..."
            onKeyPress={(e) =>
              e.key === "Enter" && !isSubmitting && handleComplete()
            }
          />
          {mission.codeInstructions && (
            <p className="text-sm text-gray-600 mt-1">
              {mission.codeInstructions}
            </p>
          )}
        </div>
      );
    }

    if (isSocialMission(mission)) {
      return (
        <div className="space-y-2">
          <p className="text-gray-700">
            {mission.sharingText || "שתף את המשימה"}
          </p>
        </div>
      );
    }

    return null;
  };

  const renderActionButton = () => {
    let buttonContent: { text: string; icon: JSX.Element; disabled: boolean } =
      {
        text: "השלם משימה",
        icon: <Check className="w-4 h-4 ml-2" />,
        disabled: isSubmitting,
      };

    if (isVideoMission(mission)) {
      buttonContent = {
        text: "סיים צפייה",
        icon: <Check className="w-4 h-4 ml-2" />,
        disabled:
          !isWatching || watchTime < mission.minimumTimeSeconds || isSubmitting,
      };
    } else if (isUploadMission(mission)) {
      buttonContent = {
        text: isUploading ? "מעלה..." : "העלה תמונה",
        icon: isUploading ? (
          <Loader2 className="w-4 h-4 ml-2 animate-spin" />
        ) : (
          <Upload className="w-4 h-4 ml-2" />
        ),
        disabled: !imageFile || isSubmitting || isUploading,
      };
    } else if (isCodeMission(mission)) {
      buttonContent = {
        text: "שלח קוד",
        icon: <Code className="w-4 h-4 ml-2" />,
        disabled: !code.trim() || isSubmitting,
      };
    } else if (isSocialMission(mission)) {
      buttonContent = {
        text: "שתף",
        icon: <Share2 className="w-4 h-4 ml-2" />,
        disabled: isSubmitting,
      };
    }

    return (
      <Button onClick={handleComplete} disabled={buttonContent.disabled}>
        {isSubmitting ? (
          <Loader2 className="w-4 h-4 ml-2 animate-spin" />
        ) : (
          buttonContent.icon
        )}
        {buttonContent.text}
      </Button>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle className="text-xl font-bold">
            {mission.title}
          </DialogTitle>
        </DialogHeader>

        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="py-4 space-y-4">
          {mission.imageUrl && !isVideoMission(mission) && (
            <div className="mb-4">
              <img
                src={mission.imageUrl}
                alt="תמונה לדוגמא"
                className="rounded-lg max-h-48 w-auto mx-auto"
              />
            </div>
          )}

          {renderMissionContent()}

          {requirements.length > 0 && (
            <div className="p-4 bg-gray-50 rounded-lg">
              <h4 className="font-medium mb-2">דרישות המשימה:</h4>
              <ul className="list-disc list-inside space-y-1 text-sm text-gray-600">
                {requirements.map((req, index) => (
                  <li key={index}>{req}</li>
                ))}
              </ul>
            </div>
          )}

          {mission.description && (
            <p className="text-gray-700">{mission.description}</p>
          )}
        </div>

        <DialogFooter className="gap-2">
          <Button
            variant="outline"
            onClick={handleClose}
            disabled={isSubmitting || isUploading}
          >
            ביטול
          </Button>
          {renderActionButton()}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MissionDialog;
