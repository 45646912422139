import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { auth, db, googleProvider } from "@/lib/firebase";
import UserSetupDialog from "@/components/auth/UserSetupDialog";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { FcGoogle } from "react-icons/fc";

interface RegisterFormData {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
  acceptMarketing: boolean;
}

const RegisterPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSetupDialog, setShowSetupDialog] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<string>("");
  const [formData, setFormData] = useState<RegisterFormData>({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false,
    acceptMarketing: true,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheckboxChange = (name: keyof RegisterFormData) => {
    setFormData((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const validateForm = () => {
    if (!formData.acceptTerms) {
      setError("עליך לאשר את תנאי השימוש כדי להירשם");
      return false;
    }
    if (formData.password !== formData.confirmPassword) {
      setError("הסיסמאות אינן תואמות");
      return false;
    }
    if (formData.password.length < 6) {
      setError("הסיסמה חייבת להכיל לפחות 6 תווים");
      return false;
    }
    return true;
  };

  const createUserDocument = async (
    uid: string,
    username: string,
    email: string | null,
    photoURL?: string
  ) => {
    await setDoc(doc(db, "users", uid), {
      username,
      email,
      isAdmin: false,
      points: 0,
      createdAt: serverTimestamp(),
      lastLogin: serverTimestamp(),
      lastUpdated: serverTimestamp(),
      lastUsernameChange: serverTimestamp(),
      completedTasks: [],
      wonCodes: [],
      profileImage: photoURL || "/avatars/default1.svg",
      status: "active",
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      await createUserDocument(
        userCredential.user.uid,
        formData.username,
        formData.email
      );

      navigate("/");
    } catch (err: any) {
      if (err?.code === "auth/email-already-in-use") {
        setError("כתובת האימייל כבר רשומה במערכת");
      } else {
        setError("ההרשמה נכשלה. אנא נסה שוב.");
        console.error(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setError(null);
    setIsLoading(true);

    try {
      console.log("Starting Google sign in process...");
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      console.log("Google sign in successful", {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
      });

      if (!user.email) {
        throw new Error("No email provided from Google");
      }

      // Check if user exists
      const userDoc = await getDoc(doc(db, "users", user.uid));

      if (!userDoc.exists()) {
        console.log("New user - creating document...");
        // Create new user document
        await createUserDocument(
          user.uid,
          user.displayName || "משתמש חדש",
          user.email,
          user.photoURL || "/avatars/default1.svg"
        );

        // Show setup dialog for new users
        setCurrentUserId(user.uid);
        setShowSetupDialog(true);
      } else {
        console.log("Existing user - updating login time...");
        // Update last login for existing users
        await setDoc(
          doc(db, "users", user.uid),
          {
            lastLogin: serverTimestamp(),
            lastUpdated: serverTimestamp(),
          },
          { merge: true }
        );
        navigate("/");
      }
    } catch (err: any) {
      console.error("Google sign in error:", err);

      if (err?.code === "auth/popup-closed-by-user") {
        setError("החלון נסגר לפני השלמת ההרשמה");
      } else if (
        err?.code === "auth/account-exists-with-different-credential"
      ) {
        setError("כתובת האימייל כבר רשומה עם שיטת התחברות אחרת");
      } else {
        setError("ההרשמה באמצעות Google נכשלה. אנא נסה שוב.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div
      className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-purple-500 via-purple-600 to-pink-500"
      dir="rtl"
    >
      <Card className="w-full max-w-md">
        <CardHeader>
          <h2 className="text-center text-2xl font-bold">הרשמה</h2>
        </CardHeader>

        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <Input
                type="text"
                name="username"
                placeholder="שם משתמש"
                value={formData.username}
                onChange={handleInputChange}
                required
                disabled={isLoading}
                className="w-full"
              />
            </div>

            <div>
              <Input
                type="email"
                name="email"
                placeholder="אימייל"
                value={formData.email}
                onChange={handleInputChange}
                required
                disabled={isLoading}
                className="w-full"
              />
            </div>

            <div>
              <Input
                type="password"
                name="password"
                placeholder="סיסמה"
                value={formData.password}
                onChange={handleInputChange}
                required
                disabled={isLoading}
                className="w-full"
              />
            </div>

            <div>
              <Input
                type="password"
                name="confirmPassword"
                placeholder="אימות סיסמה"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
                disabled={isLoading}
                className="w-full"
              />
            </div>

            <div className="space-y-2">
              <div className="flex items-center space-x-2 space-x-reverse">
                <Checkbox
                  id="terms"
                  checked={formData.acceptTerms}
                  onCheckedChange={() => handleCheckboxChange("acceptTerms")}
                  disabled={isLoading}
                />
                <label htmlFor="terms" className="text-sm">
                  קראתי ואני מסכים ל
                  <Link
                    to="/terms"
                    className="text-blue-600 hover:text-blue-500 mx-1"
                  >
                    תנאי השימוש
                  </Link>
                  ול
                  <Link
                    to="/privacy"
                    className="text-blue-600 hover:text-blue-500 mx-1"
                  >
                    מדיניות הפרטיות
                  </Link>
                </label>
              </div>

              <div className="flex items-center space-x-2 space-x-reverse">
                <Checkbox
                  id="marketing"
                  checked={formData.acceptMarketing}
                  onCheckedChange={() =>
                    handleCheckboxChange("acceptMarketing")
                  }
                  disabled={isLoading}
                />
                <label htmlFor="marketing" className="text-sm">
                  אני מסכים לקבל עדכונים ומבצעים במייל
                </label>
              </div>
            </div>

            <Button type="submit" disabled={isLoading} className="w-full">
              {isLoading ? "נרשם..." : "הרשם"}
            </Button>
          </form>

          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">או</span>
            </div>
          </div>

          <Button
            type="button"
            variant="outline"
            onClick={handleGoogleSignIn}
            disabled={isLoading}
            className="w-full"
          >
            <FcGoogle className="w-5 h-5 ml-2" />
            הרשם עם Google
          </Button>
        </CardContent>

        <CardFooter className="text-center">
          <p className="text-sm text-gray-600">
            כבר יש לך חשבון?{" "}
            <Link
              to="/login"
              className="font-semibold text-blue-600 hover:text-blue-500"
            >
              התחבר כאן
            </Link>
          </p>
        </CardFooter>
      </Card>

      {showSetupDialog && (
        <UserSetupDialog
          isOpen={showSetupDialog}
          onClose={() => setShowSetupDialog(false)}
          userId={currentUserId}
          initialUsername=""
        />
      )}
    </div>
  );
};

export default RegisterPage;
