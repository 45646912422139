import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/common/Dialog";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";

const BetaDialog = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const hasAccepted = localStorage.getItem("betaAccepted");
    if (hasAccepted) {
      setIsOpen(false);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("betaAccepted", "true");
    setIsOpen(false);
  };

  if (localStorage.getItem("betaAccepted")) {
    return null;
  }

  return (
    <Dialog open={isOpen} modal>
      <DialogContent className="sm:max-w-[500px] glass">
        <DialogHeader>
          <DialogTitle className="flex items-center text-xl">
            <span className="bg-yellow-500 text-black text-sm px-2 py-0.5 rounded ml-2">
              בטא
            </span>
            גרסת בטא - הצהרת שימוש
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <p>
            האפליקציה נמצאת כרגע <strong>בגרסת בטא</strong>, ולכן ייתכנו תקלות
            טכניות, אובדן נקודות או בעיות בקודים.
          </p>

          <ul className="list-disc list-inside space-y-2 text-sm">
            <li>אנו עושים כל מאמץ לשפר את המערכת ולוודא שהכול פועל כשורה</li>
            <li>
              <strong>אין אנו אחראים לנזקים, אובדן או תקלה</strong> שעלולים
              להתרחש בתקופת הבטא
            </li>
            <li>
              במקרה של בעיות, ניתן לפנות לתמיכה דרך פרטי ההתקשרות המופיעים בהמשך
            </li>
          </ul>

          <div className="items-top flex gap-2">
            <Checkbox
              id="accept"
              className="mt-1 border-white data-[state=checked]:bg-white/20 data-[state=checked]:text-white"
              checked={accepted}
              onCheckedChange={(checked) => setAccepted(checked === true)}
            />
            <div className="grid gap-1.5 leading-none">
              <label
                htmlFor="accept"
                className="text-sm font-medium leading-none text-white peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                קראתי והבנתי את המשמעות של שימוש בגרסת בטא
              </label>
            </div>
          </div>
        </div>

        <DialogFooter>
          <Button
            onClick={handleAccept}
            disabled={!accepted}
            className="w-full bg-white/20 hover:bg-white/30 text-white"
          >
            אני מסכים ורוצה להמשיך
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default BetaDialog;
